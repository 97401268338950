// import React, { useEffect, useState } from "react";
// import {
//   Autocomplete,
//   Box,
//   CardContent,
//   FormControl,
//   Grid,
//   InputAdornment,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import {
//   ButtonWrapper,
//   CommonWrapper,
//   PageTitle,
//   PageWrapper,
//   TitleWrapper,
//   ExternalInfoTypography,
//   ExternalMarkTypography,
//   DefaultButton,
//   ExternalAllowButton
// } from "./PermissionStyle";

// import { LoadingButton } from "@mui/lab";
// import AnimateButton from '../../components/common/formStyle/AnimateButton';
// import CircularProgress from "@mui/material/CircularProgress";
// import { useNavigate, useParams } from "react-router-dom";
// import { CustomPaper } from "../../components/common/style";
// // import BANKLIST from "../../_mockData_/BankList";
// import { toast } from "react-toastify";
// import GetAxiosInstance from "../../components/common/api";
// import { getUserData } from "../../components/common/common";
// import banksList from "../../util/bankList";
// import decodeJwt from "jwt-decode";
// import { sendEventToExternalApp } from "../../components/common/common"

// import MainLogo from "../../assets/logo-active.png";
// import { BusinessPartnerService } from "../../services/businessPartner.service";
// import { ACCOUNT_TYPES } from "../../util/accountTypes";

// const monthRange = [
//   { title: "1 Month", value: 1 },
//   {
//     title: "3 Months",
//     value: 3,
//   },
//   {
//     title: "6 Months",
//     value: 6,
//   },
//   {
//     title: "9 Months",
//     value: 9,
//   },
//   {
//     title: "12 Months",
//     value: 12,
//   },
// ];

// const accountTypeList = ACCOUNT_TYPES;

// const ExternalAccountInformation = () => {

//   const theme = useTheme();
//   const { externalAppConsentId, publicKey } = useParams();
//   const [userData, setUserData] = useState();
//   useEffect(() => {
//     let userData = getUserData();
//     setUserData(userData);
//     sessionStorage.setItem("eDoc:ExternalAppConsentId", externalAppConsentId);
//   }, []);
//   const [accountDetails, setAccountDetails] = useState({ statementDuration: 1 });
//   const [bankList, setBankList] = useState([]);

//   const [businessPartnerList, setBusinessPartnerList] = useState([]);
//   const [loader, setLoader] = useState(false);
//   const [companyName, setCompanyName] = useState('');
//   const [selectedBankLogo, setSelectedBankLogo] = useState("");
//   const [isGuests, setIsGuests] = useState(true);
//   const [selectedMonth, setSelectedMonth] = useState([]);
//   const [accountNumberError, setAccountNumberError] = useState("");
//   const [bankSelectError, setBankSelectError] = useState("");
//   let [statement, setStatement] = useState([]);

//   const [businessPartnerId, setBusinessPartnerId] = useState("");
//   const [accountTypeError, setAccountTypeError] = useState('');

//   let navigate = useNavigate();

//   const getBusinessPartners = async () => {
//     const businessPartners = await new BusinessPartnerService().GetBusinessPartners();
//     setBusinessPartnerList(businessPartners);

//   }

//   const getToken = async () => {

//     return GetAxiosInstance("orchestrator/app/user-token", "post", {
//       consentId: externalAppConsentId,
//       publicKey
//     }).then((res) => {
//       if (res?.data?.codes === 200) {
//         let decode = decodeJwt(res?.data?.data?.accessToken);
//         let obj = {
//           accessToken: res?.data?.data?.accessToken,
//           refreshToken: res?.data?.data?.refreshToken,
//         };
//         obj = { ...obj, ...decode };
//         setCompanyName(decode.appDisplayName);
//         localStorage.setItem("userData", JSON.stringify(obj));
//         return obj;

//       } else if (window.parent) {
//         sendEventToExternalApp('ERROR', 'Cosent Cancelled by user');
//         setTimeout(() => { window.close(); }, 500);
//         return null;
//       }
//     });
//   };

//   useEffect(async () => {
//     document.body.classList.add('white-bg');
//     localStorage.removeItem("userData");
//     await getToken();
//     await getBusinessPartners();
//     setBankList(await banksList());
//   }, []);

//   useEffect(() => {
//     bankList.find((item) => {
//       if (item.bankName === accountDetails?.bankName) {
//         setSelectedBankLogo(item.icon);
//       }
//     });
//   }, [accountDetails]);

//   useEffect(() => {
//     statement = [...statement];
//     statement.forEach((v) => {
//       v.months = selectedMonth;
//     });
//     setStatement(statement);
//   }, [selectedMonth]);

//   const handleCancel = () => {
//     sendEventToExternalApp('ERROR', "Request cancelled by user");
//     setTimeout(() => { window.close(); }, 500);
//   }

//   const handleAllow = () => {
//     let accountNumberError = "";
//     let bankSelectError = "";
//     if (!accountDetails?.number) {
//       accountNumberError = "Please Enter Account Number";
//     } else {
//       accountNumberError = "";
//     }

//     if (!accountDetails?.bankName) {
//       bankSelectError = "Please select Bank";
//     } else {
//       bankSelectError = "";
//     }

//     setAccountTypeError(!accountDetails.accountType ? 'Please select Account Type' : '');
//     setAccountNumberError(accountNumberError);
//     setBankSelectError(bankSelectError);
//     if (accountDetails?.number && accountDetails?.bankName && accountDetails?.accountType) {
//       let payload = {
//         consentType: "ATTACH",
//         bankName: accountDetails?.bankName,
//         accountNumber: accountDetails?.number,
//         statementDuration: accountDetails?.statementDuration,
//         businessPartnerId: businessPartnerId,
//         accountType: accountDetails?.accountType
//       }

//       setLoader(true);
//       GetAxiosInstance(`orchestrator/consent/${externalAppConsentId}/attach-account`,
//         "post",
//         payload
//       ).then((res) => {
//         if (res?.data?.codes === 200) {
//           if (res?.data?.data?.verificationType === 1) {
//             setLoader(false);
//             navigate("/pending-approval", {
//               state: { ...res.data?.data },
//             });
//           } else if (
//             res?.data?.data?.verificationType === 2 ||
//             res?.data?.data?.verificationType === 3
//           ) {
//             setLoader(false);
//             navigate("/verify-otp", {
//               state: { ...res.data?.data },
//             });
//           } else if (
//             res?.data?.data?.verificationType === 0 ||
//             res?.data?.data?.verificationType === "none"
//           ) {
//             // setLoader(false);
//             const payload = {
//               consentId: res?.data?.data?.consentId,
//             };
//             GetAxiosInstance(
//               "orchestrator/get-transactions",
//               "post",
//               payload,
//               userData.accessToken
//             ).then((res) => {
//               if (res?.data?.codes === 200) {
//                 setLoader(false);
//                 if (externalAppConsentId) {
//                   navigate(
//                     `/consent-request/${externalAppConsentId}/consent-success`,
//                     {
//                       state: {
//                         ...res.data?.data,
//                       },
//                     }
//                   );
//                 } else
//                   navigate("/dashboard", {
//                     state: {
//                       ...res.data?.data,
//                       bankName:
//                         accountDetails?.bankName === "wema"
//                           ? process.env.REACT_APP_WEMA_KEY
//                           : accountDetails?.bankName,
//                     },
//                   });
//               } else {
//                 if (externalAppConsentId) {
//                   navigate(
//                     `/consent-request/${externalAppConsentId}/consent-error`,
//                     {
//                       state: {
//                         ...res.data?.data,
//                       },
//                     }
//                   );
//                 } else {
//                   setLoader(false);
//                   toast.error(res?.response?.data?.message || res.message);
//                 }
//               }
//             });
//           }
//         } else {
//           setLoader(false);
//           toast.error(res?.response?.data?.message || res.message);
//         }
//       });
//     }

//   }
//   return (
//     <>
//       <PageWrapper>
//         <CommonWrapper>
//           <Box
//             component="img"
//             src={MainLogo}
//             alt="Logo"
//             sx={{
//               height: '44px',
//               width: '120px',
//               margin: 'auto',
//               marginBottom: '20px',
//             }}
//           />
//           <TitleWrapper>
//             <PageTitle
//               variant="Body_medium_16"
//               color="common.black"
//               style={{
//                 fontSize: '20px',
//                 paddingRight: '16px',
//                 paddingLeft: '16px',
//               }}
//             >
//               {' '}
//               {companyName} requests access to your Bank account information
//             </PageTitle>
//           </TitleWrapper>
//           <Box sx={{ width: '100%' }} className="mx-4 mt-2">
//             <CardContent
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'space-between',
//               }}
//             >
//               <Typography mb={1} variant="Body_medium_16" color="common.black">
//                 Account Number
//               </Typography>

//               <Grid container gap={4}>
//                 <TextField
//                   id="filled-hidden-label-normal"
//                   hiddenLabel
//                   inputProps={{
//                     'aria-label': 'Without label',
//                     'external-bg': 'white',
//                   }}
//                   fullWidth
//                   variant="filled"
//                   className="white-input"
//                   placeholder="Enter your account number"
//                   autoComplete="off"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   helperText={accountNumberError && accountNumberError}
//                   onChange={(e) => {
//                     setAccountDetails({
//                       ...accountDetails,
//                       number: e.target.value,
//                     });
//                   }}
//                 />
//               </Grid>
//             </CardContent>
//           </Box>
//           <Box sx={{ width: '100%' }} className="mx-4">
//             <CardContent
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'space-between',
//                 flexWrap: 'wrap',
//               }}
//             >
//               <Typography mb={1} variant="Body_medium_16" color="common.black">
//                 Account Type
//               </Typography>
//               <Grid container alignItems="flex-start">
//                 <FormControl>
//                   <RadioGroup
//                     aria-labelledby="demo-radio-buttons-group-label"
//                     name="radio-buttons-group"
//                     onClick={handleCheck}
//                   >
//                     <Stack direction={'row'}>
//                       <FormControlLabel
//                         sx={{ color: '#787878' }}
//                         value="Personal"
//                         control={<BpRadio />}
//                         labelPlacement="end"
//                         label="Personal Account"
//                       />
//                       <FormControlLabel
//                         sx={{ color: '#787878' }}
//                         value="Business"
//                         control={<BpRadio />}
//                         labelPlacement="end"
//                         label="Business Account"
//                       />
//                     </Stack>
//                   </RadioGroup>
//                 </FormControl>
//                 <Box
//                   component={'div'}
//                   style={{
//                     fontFamily: 'Inter',
//                     fontWeight: '400',
//                     fontSize: '.75rem',
//                     lineHeight: '1.66',
//                     textAlign: 'left',
//                     margin: '3px 14px 0 14px',
//                     color: 'red',
//                   }}
//                 >
//                   <Select
//                     // multiple
//                     placeholder="Select Account Type"
//                     variant="filled"
//                     name="SelectAccountType"
//                     hiddenLabel
//                     value={accountDetails.accountType}
//                     helperText={accountTypeError && accountTypeError}
//                     onChange={(e) => {
//                       setAccountDetails({
//                         ...accountDetails,
//                         accountType: e.target.value
//                       });
//                     }}
//                   >
//                     {accountTypeList.map((item) => {
//                       return (
//                         <MenuItem
//                           key={item.value}
//                           value={item.value}
//                         >
//                           {item.title}
//                         </MenuItem>
//                       );
//                     })}
//                   </Select>
//                 </FormControl>

//               </Grid>

//             </CardContent>
//           </Box>
//           <Box sx={{ width: "100%" }} className="mx-4">
//             <CardContent
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//               }}
//             >
//               <Typography mb={1} variant="Body_medium_16" color="common.black">
//                 Select Bank
//               </Typography>

//               <Grid item sx={{ width: 'inherit' }}>
//                 <Autocomplete
//                   id="select-product"
//                   options={bankList}
//                   disableClearable
//                   getOptionDisabled={(option) => !option?.enabled}
//                   getOptionLabel={(option) => option.displayName}
//                   renderOption={(props, option) => (
//                     <>
//                       <Box
//                         component="li"
//                         name={option.bankName}
//                         sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
//                         {...props}
//                       >
//                         <img
//                           loading="lazy"
//                           name={option.bankName}
//                           width="48"
//                           style={{ borderRadius: '50%' }}
//                           src={option.icon}
//                           alt=""
//                         />
//                         {option.displayName}
//                       </Box>
//                     </>
//                   )}
//                   PaperComponent={CustomPaper}
//                   onChange={(e) => {
//                     setAccountDetails({
//                       ...accountDetails,
//                       bankName: e.target.getAttribute('name'),
//                     });
//                   }}
//                   renderInput={(params) => (
//                     <TextField
//                       variant="filled"
//                       id="filled-hidden-label-normal"
//                       placeholder="Select Bank"
//                       hiddenLabel
//                       {...params}
//                       InputProps={{
//                         ...params.InputProps,
//                         startAdornment: (
//                           <InputAdornment position="start">
//                             {selectedBankLogo && (
//                               <Box
//                                 component="img"
//                                 src={selectedBankLogo}
//                                 alt=""
//                                 sx={{
//                                   width: '48px',
//                                   height: '48px',
//                                   borderRadius: '50%',
//                                 }}
//                               />
//                             )}
//                           </InputAdornment>
//                         ),
//                       }}
//                       fullWidth
//                     />
//                   )}
//                 />
//                 <p
//                   style={{
//                     fontFamily: 'Inter',
//                     fontWeight: '400',
//                     fontSize: '.75rem',
//                     lineHeight: '1.66',
//                     textAlign: 'left',
//                     margin: '3px 14px 0 14px',
//                     color: 'red',
//                   }}
//                 >
//                   {bankSelectError && bankSelectError}
//                 </p>
//               </Grid>
//             </CardContent>
//           </Box>
//           <Box sx={{ width: "100%" }} className="mx-4">
//             <CardContent
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'space-between',
//               }}
//             >
//               <Typography mb={1} variant="Body_medium_16" color="common.black">
//                 Statement Duration
//               </Typography>
//               <Grid container gap={4} alignItems="flex-start">
//                 <FormControl
//                   variant="filled"
//                   name="selectMonth"
//                   hiddenLabel
//                   inputProps={{
//                     'aria-label': 'Without label',
//                   }}
//                   value={accountDetails.statementDuration}
//                   className="w-100 pl-4"
//                   onChange={(e) => {
//                     setAccountDetails({
//                       ...accountDetails,
//                       statementDuration: e.target.value,
//                     });
//                   }}
//                 >
//                   <Select
//                     // multiple
//                     placeholder="Select Duraction"
//                     variant="filled"
//                     name="selectMonth"
//                     hiddenLabel
//                     value={accountDetails.statementDuration}
//                     onChange={(e) => {
//                       setAccountDetails({
//                         ...accountDetails,
//                         statementDuration: e.target.value,
//                       });
//                     }}
//                   >
//                     {monthRange.map((item) => {
//                       return (
//                         <MenuItem key={item.value} value={item.value}>
//                           {item.title}
//                         </MenuItem>
//                       );
//                     })}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             </CardContent>
//           </Box>
//           <Box sx={{ width: "100%" }} className="mx-4">
//             <CardContent
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//               }}
//             >
//               <Typography mb={1} variant="Body_medium_16" color="common.black">
//                 Business Partner (optional)
//               </Typography>
//               <Grid item sx={{ width: "inherit" }}>
//                 <Autocomplete
//                   id="select-business-partner"
//                   options={businessPartnerList || []}
//                   disableClearable
//                   getOptionDisabled={(option) =>
//                     localStorage.getItem("deepLinkId") ? true : false
//                   }
//                   value={
//                     businessPartnerList?.length &&
//                     businessPartnerList.find((item) => item?.id === businessPartnerId)
//                   }
//                   getOptionLabel={(option) => option.companyName}
//                   PaperComponent={CustomPaper}
//                   renderInput={(params) => (
//                     <TextField
//                       variant="filled"
//                       id="filled-hidden-label-normal"
//                       placeholder="Select..."
//                       inputProps={{ "aria-label": "Without label" }}
//                       hiddenLabel
//                       // fullWidth
//                       {...params}
//                     />
//                   )}
//                   onChange={(e) => {
//                     if (e.target.getInnerHTML()) {
//                       let selected = businessPartnerList.find(
//                         (item) => item.companyName === e.target.getInnerHTML()
//                       );
//                       setBusinessPartnerId(selected?.id);
//                     }
//                   }}
//                 />
//               </Grid>
//             </CardContent>
//           </Box>
//           <Box sx={{ width: '100%' }} className="mx-4">
//             <ExternalInfoTypography>
//               By clicking “Allow” you also agree to our{' '}
//               <ExternalMarkTypography
//                 onClick={() =>
//                   window.open(
//                     'https://e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
//                   )
//                 }
//               >
//                 End User Terms of Use
//               </ExternalMarkTypography>{' '}
//               and{' '}
//               <ExternalMarkTypography
//                 onClick={() =>
//                   window.open(
//                     'https://e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf'
//                   )
//                 }
//               >
//                 Privacy Policy
//               </ExternalMarkTypography>
//             </ExternalInfoTypography>
//           </Box>

//           <ButtonWrapper style={{ justifyContent: 'flex-end' }}>
//             <Box
//               style={{
//                 width: 'inherit',
//                 display: 'flex',
//                 justifyContent: 'flex-end',
//               }}
//             >
//               <DefaultButton
//                 className="btn btn-default mx-4"
//                 onClick={handleCancel}
//               >
//                 Cancel
//               </DefaultButton>
//               <AnimateButton>
//                 <LoadingButton
//                   size="large"
//                   type="submit"
//                   variant="contained"
//                   sx={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                   onClick={handleAllow}
//                   loadingIndicator={
//                     <CircularProgress
//                       style={{
//                         padding: '60px',
//                         color: theme.palette.orange.main,
//                       }}
//                     />
//                   }
//                   loading={loader}
//                 >
//                   Allow
//                 </LoadingButton>
//               </AnimateButton>
//             </Box>
//           </ButtonWrapper>
//         </CommonWrapper>
//       </PageWrapper>
//     </>
//   );
// };

// export default ExternalAccountInformation;

import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  CircularProgress,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ButtonWrapper,
  PageTitle,
  PageWrapper,
  TitleWrapper,
  ExternalInfoTypography,
  ExternalMarkTypography,
  DefaultButton,
  CommonWrapperExternal,
} from './PermissionStyle';

import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomPaper } from '../../components/common/style';
// import BANKLIST from "../../_mockData_/BankList";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { getUserData } from "../../components/common/common";
import banksList from "../../util/bankList";
import decodeJwt from "jwt-decode";
import { sendEventToExternalApp } from "../../components/common/common"
import MainLogo from "../../assets/logo-active.png";
import { BusinessPartnerService } from "../../services/businessPartner.service";
import { ACCOUNT_TYPES } from "../../util/accountTypes";
import { BpRadio } from '../bank-selection/BankSelectionStyle';

let monthRange = [
  { title: '1 Month', value: 1 },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];

const accountTypeList = ACCOUNT_TYPES;

const ExternalAccountInformation = () => {
  const theme = useTheme();
  const { externalAppConsentId, publicKey } = useParams();
  const [userData, setUserData] = useState();
  const [statementMonths, setStatementMonths] = useState([]);
  useEffect(() => {
    let userData = getUserData();
    setUserData(userData);
    sessionStorage.setItem('eDoc:ExternalAppConsentId', externalAppConsentId);
  }, []);
  const [accountDetails, setAccountDetails] = useState({
    statementDuration: 1,
  });
  const [bankList, setBankList] = useState([]);

  const [businessPartnerList, setBusinessPartnerList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [selectedBankLogo, setSelectedBankLogo] = useState('');
  const [isGuests, setIsGuests] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [accountNumberError, setAccountNumberError] = useState('');
  const [bankSelectError, setBankSelectError] = useState('');
  let [statement, setStatement] = useState([]);
  
  const [businessPartnerId, setBusinessPartnerId] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const [accountType, setAccountType] = useState('');
  const [appLogo, setAppLogo] = useState('');

  let navigate = useNavigate();
  const fetchAccountName = async (accountNumber, bankName) => {
    try {
      const response = await GetAxiosInstance(
        'orchestrator/account-name',
        'POST',
        { accountNumber, bankName },
        {},
        null
      );
      return response.data.data.name;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

const handleAccountNumberChange = async (e) => {
  const number = e.target.value;
  setAccountDetails((prevDetails) => ({
    ...prevDetails,
    number,
  }));

  if (number && accountDetails.bankName) {
    const accountName = await fetchAccountName(number, accountDetails.bankName);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      accountName,
    }));
  }
};

const handleBankChange = async (e) => {
  const bankName = e.target.getAttribute('name');
  setAccountDetails((prevDetails) => ({
    ...prevDetails,
    bankName,
  }));

  if (accountDetails.number && bankName) {
    const accountName = await fetchAccountName(accountDetails.number, bankName);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      accountName,
    }));
  }
};
  const getBusinessPartners = async () => {
    const businessPartners =
      await new BusinessPartnerService().GetBusinessPartners();
    setBusinessPartnerList(businessPartners);
  };

  const getToken = async () => {
    return GetAxiosInstance('orchestrator/app/user-token', 'post', {
      consentId: externalAppConsentId,
      publicKey,
    }).then((res) => {
      if (res?.data?.codes === 200) {
        let decode = decodeJwt(res?.data?.data?.accessToken);
        let obj = {
          accessToken: res?.data?.data?.accessToken,
          refreshToken: res?.data?.data?.refreshToken,
        };

        if (res?.data?.data?.app?.logo) {
          setAppLogo(res?.data?.data?.app?.logo);
        }

        obj = { ...obj, ...decode };
        setCompanyName(decode.appDisplayName);
        if (res?.data?.data?.months) {
          const month = Math.abs(res?.data?.data?.months);
          const monthRange = [
            {
              title: `${month} Month(s)`,
              value: month,
            },
          ];
          setStatementMonths(monthRange);
  
          // Automatically set the statementDuration
          setAccountDetails((prev) => ({
            ...prev,
            statementDuration: monthRange[0].value,
          }));
        }
  
        localStorage.setItem('userData', JSON.stringify(obj));
        return obj;
      } else if (window.parent) {
        sendEventToExternalApp('ERROR', 'Consent Cancelled by user');
        setTimeout(() => {
          window.close();
        }, 500);
        return null;
      }
    });
  };
  
  useEffect(async () => {
    document.body.classList.add('white-bg');
    localStorage.removeItem('userData');
    await getToken();
    await getBusinessPartners();
    setBankList(await banksList());
  }, []);

  useEffect(() => {
    bankList.find((item) => {
      if (item.bankName === accountDetails?.bankName) {
        setSelectedBankLogo(item.icon);
      }
    });
  }, [accountDetails]);

  useEffect(() => {
    statement = [...statement];
    statement.forEach((v) => {
      v.months = selectedMonth;
    });
    setStatement(statement);
  }, [selectedMonth]);

  const handleCancel = () => {
    sendEventToExternalApp('ERROR', 'Request cancelled by user');
    setTimeout(() => {
      window.close();
    }, 500);
  };

  const handleAllow = () => {
    let accountNumberError = '';
    let bankSelectError = '';
    let accountTypeError = '';
    if (!accountDetails?.number) {
      accountNumberError = 'Please Enter Account Number';
    } else {
      accountNumberError = '';
    }
    if (!accountType) {
      accountTypeError = 'Please select Account Type';
    } else {
      accountTypeError = '';
    }

    if (!accountDetails?.bankName) {
      bankSelectError = 'Please select Bank';
    } else {
      bankSelectError = '';
    }

    setAccountTypeError(accountTypeError);
    setAccountNumberError(accountNumberError);
    setBankSelectError(bankSelectError);
    if (accountDetails?.number && accountDetails?.bankName && accountType) {
      let payload = {
        consentType: 'ATTACH',
        bankName: accountDetails?.bankName,
        accountNumber: accountDetails?.number,
        statementDuration: accountDetails?.statementDuration,
        businessPartnerId: businessPartnerId,
        accountType: accountType,
      };

      setLoader(true);
      GetAxiosInstance(
        `orchestrator/consent/${externalAppConsentId}/attach-account`,
        'post',
        payload
      ).then((res) => {
        if (res?.data?.codes === 200) {

          const postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.INITIATED',
            status: 'SUCCESS',
            uniqueIdentifier: res?.data?.data?.consentId,
            subIdentifier: accountDetails?.bankName,
            data: {
              req: payload,
              res: res?.data?.data,
            },
            source: 'EXTERNAL_APP',
          };
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          if (res?.data?.data?.verificationType === 1) {
            setLoader(false);
            navigate('/pending-approval', {
              state: { ...res.data?.data, bankName: accountDetails?.bankName },
            });
          } else if (
            res?.data?.data?.verificationType === 2 ||
            res?.data?.data?.verificationType === 3
          ) {
            setLoader(false);

            navigate('/verify-otp', {
              state: { ...res.data?.data, bankName: accountDetails?.bankName },
            });
          } else if (
            res?.data?.data?.verificationType === 0 ||
            res?.data?.data?.verificationType === 'none'
          ) {
            // setLoader(false);
            const payload = {
              consentId: res?.data?.data?.consentId,
            };
            GetAxiosInstance(
              'orchestrator/get-transactions',
              'post',
              payload,
              userData.accessToken
            ).then((res) => {
              if (res?.data?.codes === 200) {
                setLoader(false);
                if (externalAppConsentId) {
                  navigate(
                    `/consent-request/${externalAppConsentId}/consent-success`,
                    {
                      state: {
                        ...res.data?.data,
                      },
                    }
                  );
                } else
                  navigate('/dashboard', {
                    state: {
                      ...res.data?.data,
                      bankName:
                        accountDetails?.bankName === 'wema'
                          ? process.env.REACT_APP_WEMA_KEY
                          : accountDetails?.bankName,
                    },
                  });
              } else {
                if (externalAppConsentId) {
                  navigate(
                    `/consent-request/${externalAppConsentId}/consent-error`,
                    {
                      state: {
                        ...res.data?.data,
                      },
                    }
                  );
                } else {
                  setLoader(false);
                  toast.error(res?.response?.data?.message || res.message);
                }
              }
            });
          }
        } else {
          const postObj = {
            journey: 'CONSENT',
            eventName: 'CONSENT.INITIATED',
            status: 'ERROR',
            uniqueIdentifier: res?.data?.data?.consentId,
            subIdentifier: accountDetails?.bankName,
            data: {
              req: payload,
              res: res.response.data.message,
            },
            source: 'EXTERNAL_APP',
          };
          GetAxiosInstance(`log/eventLog`, 'post', postObj);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
  };
  const handleCheck = (e) => {
    setAccountType(e.target.value);
  };
  return (
    <>
      <PageWrapper>
        <CommonWrapperExternal
          style={{
            backgroundColor: '#fff',
            width: '480px',
            margin: 'auto',
            border: '1px solid #dedede',
            borderRadius: '16px',
            padding: '10px',
          }}
        >
          <Box
            component="img"
            src={appLogo || MainLogo}
            alt="Logo"
            sx={{
              height: 'auto',
              width: '120px',
              margin: 'auto',
              marginBottom: '20px',
            }}
          />
          <TitleWrapper>
            <PageTitle
              variant="Body_medium_16"
              color="common.black"
              style={{
                fontSize: '20px',
                paddingRight: '16px',
                paddingLeft: '16px',
              }}
            >
              {' '}
              {companyName} requests access to your Bank account information
            </PageTitle>
          </TitleWrapper>
          <Box sx={{ width: '100%' }} className="mx-4 mt-2">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography mb={1} variant="Body_medium_16" color="common.black">
                Account Number
              </Typography>

              <Grid container gap={4}>
                <TextField
                  id="filled-hidden-label-normal"
                  hiddenLabel
                  inputProps={{
                    'aria-label': 'Without label',
                    'external-bg': 'white',
                  }}
                  fullWidth
                  variant="filled"
                  className="white-input"
                  placeholder="Enter your account number"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={accountNumberError && accountNumberError}
                  onChange={handleAccountNumberChange}
                
                />
              </Grid>
                <Typography variant="Body_medium_14" color="grey.light" mt={1}>
                      {accountDetails?.accountName}
                </Typography>
              
            </CardContent>
          </Box>
          <Box sx={{ width: '100%' }} className="mx-4">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography mb={1} variant="Body_medium_16" color="common.black">
                Account Type
              </Typography>
              <Grid container alignItems="flex-start">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onClick={handleCheck}
                  >
                    <Stack direction={'row'}>
                      <FormControlLabel
                        sx={{ color: '#787878' }}
                        value="Personal"
                        control={<BpRadio />}
                        labelPlacement="end"
                        label="Personal Account"
                      />
                      <FormControlLabel
                        sx={{ color: '#787878' }}
                        value="Business"
                        control={<BpRadio />}
                        labelPlacement="end"
                        label="Business Account"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <Box
                  component={'div'}
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '.75rem',
                    lineHeight: '1.66',
                    textAlign: 'left',
                    margin: '3px 14px 0 14px',
                    color: 'red',
                  }}
                >
                  {accountTypeError && accountTypeError}
                </Box>
              </Grid>
            </CardContent>
          </Box>
          <Box sx={{ width: '100%' }} className="mx-4">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography mb={1} variant="Body_medium_16" color="common.black">
                Select Bank
              </Typography>

              <Grid item sx={{ width: 'inherit' }}>
                <Autocomplete
                  id="select-product"
                  options={bankList}
                  disableClearable
                  getOptionDisabled={(option) => !option?.enabled}
                  getOptionLabel={(option) => option.displayName || ''}
                  renderOption={(props, option) => (
                    <>
                      <Box
                        component="li"
                        name={option.bankName}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          name={option.bankName}
                          width="48"
                          style={{ borderRadius: '50%' }}
                          src={option.icon}
                          alt=""
                        />
                        {option.displayName}
                      </Box>
                    </>
                  )}
                  PaperComponent={CustomPaper}
                  onChange={handleBankChange}
                  renderInput={(params) => (
                    <TextField
                      variant="filled"
                      id="filled-hidden-label-normal"
                      placeholder="Select Bank"
                      hiddenLabel
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {selectedBankLogo && (
                              <Box
                                component="img"
                                src={selectedBankLogo}
                                alt=""
                                sx={{
                                  width: '48px',
                                  height: '48px',
                                  borderRadius: '50%',
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  )}
                />
                <Box
                  component={'div'}
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '.75rem',
                    lineHeight: '1.66',
                    textAlign: 'left',
                    margin: '3px 14px 0 14px',
                    color: 'red',
                  }}
                >
                  {bankSelectError && bankSelectError}
                </Box>
              </Grid>
            </CardContent>
          </Box>
          <Box sx={{ width: '100%' }} className="mx-4">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography mb={1} variant="Body_medium_16" color="common.black">
                Statement Duration
              </Typography>
              <Grid container gap={4} alignItems="flex-start">
                <FormControl
                  variant="filled"
                  name="selectMonth"
                  hiddenLabel
                  inputProps={{
                    'aria-label': 'Without label',
                  }}
                  value={accountDetails.statementDuration}
                  className="w-100 pl-4"
                  onChange={(e) => {
                    setAccountDetails({
                      ...accountDetails,
                      statementDuration: e.target.value,
                    });
                  }}
                >
                  <Select
                    // multiple
                    placeholder="Select Duraction"
                    variant="filled"
                    name="selectMonth"
                    hiddenLabel
                    value={accountDetails.statementDuration}
                    onChange={(e) => {
                      setAccountDetails({
                        ...accountDetails,
                        statementDuration: e.target.value,
                      });
                    }}
                  >
                    {statementMonths.map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </CardContent>
          </Box>

          <Box sx={{ width: '100%' }} className="mx-4">
            <ExternalInfoTypography>
              By clicking “Allow” you also agree to our{' '}
              <ExternalMarkTypography
                onClick={() =>
                  window.open(
                    'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
                  )
                }
              >
                End User Terms of Use
              </ExternalMarkTypography>{' '}
              and{' '}
              <ExternalMarkTypography
                onClick={() =>
                  window.open(
                    'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+ng+Privacy+Policy.pdf'
                  )
                }
              >
                Privacy Policy
              </ExternalMarkTypography>
            </ExternalInfoTypography>
          </Box>

          <ButtonWrapper style={{ justifyContent: 'flex-end' }}>
            <Box
              style={{
                width: 'inherit',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <DefaultButton
                className="btn btn-default mx-4"
                onClick={handleCancel}
              >
                Cancel
              </DefaultButton>
              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={handleAllow}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loader}
                >
                  Allow
                </LoadingButton>
              </AnimateButton>
            </Box>
          </ButtonWrapper>
          {appLogo ?
            <>
              <Box
                variant="Body_medium_16"
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: '12px'
                }}
                className="mx-4 my-4">

                <Typography mb={1} variant="Body_medium_16" color="common.black">
                  Powered by E-DOC
                </Typography>
              </Box>
              <Box
                component="img"
                src={MainLogo}
                alt="Logo"
                sx={{
                  height: 'auto',
                  width: '120px',
                  margin: 'auto',
                  marginBottom: '20px',
                }}
              />
            </>

            : ""}
        </CommonWrapperExternal>
      </PageWrapper>
    </>
  );
};

export default ExternalAccountInformation;
