import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Styled table cell components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  fontSize: '0.9rem',
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  backgroundColor: '#132933',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const SectionHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  textAlign: 'center',
  borderBottom: 'none',
}));

const InstructionCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontSize: '0.85rem',
  textAlign: 'center',
  color: theme.palette.common.white,
  fontStyle: 'italic',
}));

// Data for Board of Directors
const FNBboardData = [
  {
    name: '',
    position: 'Chairperson',
    memberSince: 'Chairperson since:',
    previousPosition: 'Independent Non-Executive Director',
    education: '',
  },
  {
    name: 'J V Ndlangamandla',
    position: 'Board Chairperson, Independent Non-Executive Director',
    memberSince: '3-Feb-16',
    previousPosition: '',
    education: '',
  },
  {
    name: 'D T Mbingo',
    position: 'Chief Executive Officer (Outgoing)',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Thokozani Dlamini',
    position: 'Chief Executive Officer (Incoming)',
    memberSince: 'Jan. 2025',
    previousPosition: '',
    education: '',
  },
  {
    name: 'S de Sousa',
    position: 'Independent Non-Executive Director',
    memberSince: '5-Feb-10',
    previousPosition: '',
    education: '',
  },
  {
    name: 'D E Wright',
    position:
      'Independent Non-Executive Director, Member and Chair (Independent Loan Review Committee)',
    memberSince: '3-Feb-16',
    previousPosition: 'Member and Chair (Independent Loan Review Committee), ',
    education: '',
  },
  {
    name: 'S L Balsdon',
    position: 'Non-Executive Director',
    memberSince: '21-Apr-17',
    previousPosition: '',
    education: '',
  },
  {
    name: 'E B Arden',
    position:
      'Non-Executive Director, Member and Chair (Audit Committee), Independent',
    memberSince: '24-Apr-19',
    previousPosition: 'Member and Chair (Audit Committee), Independent ',
    education: '',
  },
  {
    name: 'L J Haynes',
    position: 'Non-Executive Director',
    memberSince: '11-Nov-21',
    previousPosition: '',
    education: '',
  },
  {
    name: 'J M Gule',
    position:
      'Independent Non-Executive Director, Member and Chair (Remuneration Committee)',
    memberSince: '11-Feb-21',
    previousPosition: 'Member and Chair (Remuneration Committee),',
    education: '',
  },
];

// Data for Executive Management
const FNBexecutiveData = [
  {
    name: 'D T Mbingo',
    position: 'Chief Executive Officer (Outgoing)',
    startDate: 'till Dec. 2024',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Njabulo Dlamini',
    position: 'Chief Financial Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Thokozani Dlamini',
    position: 'Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Siboniso Mdluli',
    position: 'Chief Operations Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Hlengiwe Msibi',
    position: 'Company Secretary',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Gugu Matiwane',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Zethu Dlamini',
    position: 'Chief Marketing Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Panuel Gwebu',
    position: 'Chief Compliance Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mncedzi Ngomane',
    position: 'Executive Head - Retail',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Ncamiso Dlamini',
    position: 'Executive Head - Corporate & Commercial',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Thandeka Dlamini',
    position: 'Executive Head - Credit',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Lobesutfu Dlamini',
    position: 'Executive Head - Human Capital',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mxolisi Gumedze',
    position: 'Executive Head - Internal Audit',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const ABCboardData = [
  {
    name: 'Lerato Nthamo Moselhyane',
    position: 'Board Chairperson, Independent Non-Executive Director',
    memberSince: '23-Jul-14',
    previousPosition:
      'Over 17 years in various roles in different industries, former CFO at a leading Life Insurance Company',
    education:
      'Chartered Accountant (ACCA), MBA from Oxford Brookes University',
  },
  {
    name: 'Shepard Aisam',
    position: 'Managing Director',
    memberSince: '4-Jan-24',
    previousPosition:
      'Group Head of Public Sector, Business & Commercial Banking at Standard Bank Group',
    education:
      'BBA from University of Botswana, MSc in Strategic Management from University of Derby',
  },
  {
    name: 'Jacob Mooketsi Mathibane',
    position: 'Independent Non-Executive Director',
    memberSince: '12-Nov-14',
    previousPosition:
      'Former CEO of Tumstar Holdings, Group Strategy & Business Development Manager for Letshego Holdings Limited',
    education: 'Bachelor of Commerce in Accounting, Pursuing MBA at Mancosa',
  },
  {
    name: 'Joshua Benjamin Galeforolwe',
    position: 'Senior Independent Non-Executive Director',
    memberSince: '1-Apr-18',
    previousPosition:
      'Managing Consultant at West Cliff Capital, Former Chief Executive of Public Enterprises Evaluation and Privatisation Agency (PEEPA)',
    education: 'BCom in Accounting, BA in Economics',
  },
  {
    name: 'Boiki Matema Wabo Tema',
    position: 'Independent Non-Executive Director',
    memberSince: '3-Oct-18',
    previousPosition:
      '24 years of banking experience, including Deputy Director and Coverage Director at Rand Merchant Bank Botswana and former positions at First National Bank Botswana',
    education:
      'BA in Economics, MSc in Strategic Management from University of Derby',
  },
  {
    name: 'Noni Moselhe',
    position: 'Independent Non-Executive Director',
    memberSince: '1-Nov-18',
    previousPosition: 'Deputy CEO at Botswana Housing Corporation (BHC)',
    education: 'Bachelor of Administration',
  },
  {
    name: 'John Boso Sebabi',
    position: 'Independent Non-Executive Director',
    memberSince: '3-Jun-20',
    previousPosition:
      'Deputy Director General of Namibia Social Security Board, Central Bank of Zambia',
    education:
      'MSc in International Economics, Banking and Finance from Cardiff University',
  },
  {
    name: 'Lynda Matika',
    position: 'Independent Non-Executive Director',
    memberSince: '1-Jul-22',
    previousPosition: 'Legal roles, Board member at Access Bank Botswana',
    education:
      "LLB, Postgraduate Diploma in Legal Drafting, Master's in International Business Law",
  },
  {
    name: 'Oluseyi Kumapayi',
    position: 'Non-Executive Director',
    memberSince: '5-Nov-21',
    previousPosition:
      'Director of African Subsidiaries at Access Bank PLC, Former Group CFO at Access Bank PLC',
    education:
      "Master's in Mechanical Engineering, Bachelor's in Agricultural Engineering, Harvard Business School alumnus",
  },
  {
    name: 'Robert Michael Yowerth Giles',
    position: 'Non-Executive Director',
    memberSince: '5-Nov-21',
    previousPosition:
      'Senior Banking Advisor at Access Bank PLC, Former roles at Diamond Bank PLC and Access Bank PLC',
    education:
      'BSc in Geography, Postgraduate Diploma in Management from University of Leicester Business School',
  },
  {
    name: 'Ratang Isao-Mohalatsi',
    position: 'Finance Director',
    memberSince: '1-Sep-19',
    previousPosition:
      'Various roles at Mutual Botswana, Botswana Savings Bank, and Deloitte',
    education:
      'BSc in Economics and Accounting, ACCA, MSc in Strategic Management',
  },
];

// Data for Executive Management
const ABCexecutiveData = [
  {
    name: 'Muzonda Chishimba',
    position: 'Deputy Managing Director',
    startDate: '1-Oct-22',
    previousPosition:
      'Executive Director - Access Bank PLC, 25 years of banking experience including Citibank',
    education:
      'MBA in Finance from Heriot Watt Business School, BSc Honours in Computing',
  },
  {
    name: 'Godwin Chakwunta',
    position: 'Chief Operations Officer',
    startDate: '',
    previousPosition:
      'Over 20 years in banking roles at Standard Bank of Africa PLC and Zenith Bank PLC',
    education:
      'BSc in Mathematics and Statistics, MBA from Ahmadu Bello University, MSc in Finance and Management from Cranfield University',
  },
  {
    name: 'Thato Mmie',
    position: 'Head, Legal and Company Secretary',
    startDate: '',
    previousPosition:
      'Over 20 years in legal and corporate governance, former roles at ABC Holdings Limited and Standard Chartered Bank',
    education: 'LLB from University of Botswana',
  },
  {
    name: 'Pauline Modisegae',
    position: 'Head of Wholesale Banking',
    startDate: '',
    previousPosition:
      'Over 30 years banking experience, former roles at RMB Botswana, FNBB, Botswana Bank of Botswana',
    education:
      'MBA from University of Stellenbosch, Bachelor of Economics and Accounting',
  },
  {
    name: 'Segametsi Sebantabo',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition:
      'Former roles in Barclays Bank and Standard Bank including Head of Credit Origination',
    education:
      "Master's in Leadership and Change Management from Leeds Metropolitan University, BA Honours in Economics and Environmental Science",
  },
];
const IMBboardData = [
  {
    name: 'Jennifer Morel',
    position: 'Director and Chairperson',
    memberSince: 'since August 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'David Howes',
    position: 'Director',
    memberSince: 'Director since January 2018, reappointed July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Michael Bluemer',
    position: 'Director',
    memberSince: 'since February 2020, reappointed July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Andrew Bainbridge',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Helene Maiche',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Odile Vidot',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Christophe Edmond',
    position: 'Director and Chief Executive Officer',
    memberSince: 'since July 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Philippe Pierre',
    position: 'Director and Deputy Chief Executive Officer',
    memberSince: 'since October 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Oliver Bastienne',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
];

// Data for Executive Management
const IMBexecutiveData = [
  {
    name: 'Christophe Edmond',
    position: 'Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Philippe Pierre',
    position: 'Deputy Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Joel Isaac',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mariza Tirant',
    position: 'Chief Financial Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Sandra Hall',
    position: 'Chief Compliance Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Anil Amplehy Ladouce',
    position: 'Chief Treasury Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Laurie-Mae Lepathy',
    position: 'Chief Corporate and Marketing Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Madeleine Aalfs',
    position: 'Chief Human Resources Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Laurain Confait',
    position: 'Chief Operations Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Maureen Brioche',
    position: 'Chief Branch Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const LETboardData = [
  {
    name: 'Maryvonne Palanduz',
    position: 'Chairperson',
    memberSince: 'Chairperson since:2017-05-17',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Kudzi Chigiji',
    position: 'Independent Non-Executive Director',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Sven Bloch Von',
    position: 'Independent Non-Executive Director',
    memberSince: '2016',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Maria Nakale',
    position: 'Independent Non-Executive Director',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Karl-Stefan Altman',
    position: 'Executive Director',
    memberSince: '2021',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Ester Kali',
    position: 'CEO',
    memberSince: '2016',
    previousPosition: '',
    education: 'MBA from Maastricht School of Management.',
  },
  {
    name: 'Rosalia Martins-Hausiku',
    position: 'Independent Non-Executive Director',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Sven Bloch von Blottnitz',
    position: 'Independent Non-Executive Director',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
];
const LETexecutiveData = [
  {
    name: 'Ester Kali',
    position: 'Chief Executive Officer',
    startDate: '2014',
    previousPosition: '',
    education: '',
  },
  {
    name: "O'Rute Uandara",
    position: 'Chief Operating Officer',
    startDate: '2015',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Diana Mokhatu',
    position: 'Human Resources Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Karl-Stefan Altman',
    position: 'Chief Finance Officer',
    startDate: '',
    previousPosition:
      'Previously, he worked as the Chief Financial Officer at Nedbank Namibia Ltd.',
    education:
      'He completed his undergraduate degree at the University of Stellenbosch.',
  },
  {
    name: 'Alletta Shifotoka',
    position: 'Compliance Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mignon Klein',
    position: 'Corporate Secretary',
    startDate: '2022',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Jaco Kruger',
    position: 'Sales and Marketing',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Grace Ntuli',
    position: 'Comptroller/Controller/Auditor',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const MCBboardData = [
  {
    name: 'Mr Visvanaden SOONDRAM',
    position: 'Chairman',
    memberSince: 'Jul-22',
    previousPosition: '',
    education: "Master's in Finance",
  },
  {
    name: 'Mr Jean Paul Emmanuel AROUFF',
    position: 'Non-Independent Non-Executive Director',
    memberSince: 'Jul-20',
    previousPosition: 'Previously Editor-In-Chief of Business Magazine',
    education: "Master's in Journalism",
  },
  {
    name: 'Mr Raoul GUFFLET',
    position: 'Non-Independent Non-Executive Director',
    memberSince: 'Jul-21',
    previousPosition: 'Previously Deputy Chief Executive Officer of a renowned bank in Mauritius',
    education: 'Master’s Degree in Economics (specialisation in Finance)',
  },
  {
    name: 'Mr Muhammad Azeem SALEHMOHAMED',
    position: 'Non-Independent Non-Executive Director',
    memberSince: 'Jul-20',
    previousPosition: '',
    education: 'MA (Hons) in Public Policy and Management',
  },
  {
    name: 'Ms Preshnee RAMCHURN',
    position: 'Secretary to the Board',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Imalambaal KICHENIN',
    position: 'Independent Non-Executive Director',
    memberSince: 'Mar-20',
    previousPosition: '',
    education: 'Bachelor of Laws (LLB)',
  },
  {
    name: 'Mr Rajcoomar RAMPERTAB, CSK',
    position: 'Independent Non-Executive Director',
    memberSince: 'Mar-20',
    previousPosition: '',
    education: 'Bachelor of Laws (LLB)',
  },
  {
    name: 'Ms Oumila SIBARTIE',
    position: 'Independent Non-Executive Director',
    memberSince: 'Aug-20',
    previousPosition: '',
    education: 'Master’s Degree in Economics (USA)',
  },
  {
    name: 'Mr Ranapartab TACOURI, GCSK',
    position: 'Independent Non-Executive Director',
    memberSince: 'Mar-20',
    previousPosition: 'Managing Director of the Bank of Mauritius',
    education: 'Master’s Degree in Economics',
  },
  {
    name: 'Mr Premchand MUNGAR',
    position: 'Executive Director',
    memberSince: 'Nov-23',
    previousPosition: 'Served as the Chief Executive and Executive Director of MauBank Limited from 2018 to 2023',
    education: 'MSc Finance and Financial Law, SOAS - University of London, United Kingdom',
  },
  {
    name: 'Mr Lawrence Eric WONG TAK WAN',
    position: 'Independent Non-Executive Director',
    memberSince: 'Oct-23',
    previousPosition: 'Founder and CEO of Aryze Company Ltd',
    education: 'Bachelor of Commerce with Accounting, Banking & Finance, La Trobe University, Australia',
  },
];

const MCBexecutiveData = [
  {
    name: 'Mr Premchand MUNGAR',
    position: 'Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Rita Devi PERSAND GUJADHUR',
    position: 'Chief Financial Management Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Veronique LIM HOYE YEE',
    position: 'Chief Credit Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Nadim IMRIT',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Sanjaiye RAWOTEEA',
    position: 'Head Consumer Banking',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Teddy Kian Lim ALING',
    position: 'Head Finance and Capital Management',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Ms Deorani KHELAWON',
    position: 'Head Operations Centre',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Deeagarajen Manogaren SOONDRAM',
    position: 'Head Strategic Planning and Execution',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Anju ISSUR',
    position: 'Head Financial Markets',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Rajnish LUTCHMAH',
    position: 'Head Corporate Domestic Banking',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Dimple BHUDOYE',
    position: 'Head International Banking - Corporate (Structured and Trade Finance)',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Percy PHILIPS',
    position: 'Head Retail Banking',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Dharmendranath (Darmen) HURKOO',
    position: 'Head Business Banking, SME and Microfinance',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Sarika Devi JANGI-JUGNAUTH',
    position: 'Head International Banking - FI and Sovereigns',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Norman Dean Thia Kim Luck FON SING',
    position: 'Head Leverage Finance',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Yogendra APPADOO',
    position: 'Head International Banking - Global Business',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Ashley SEEBORUTH',
    position: 'Head Payment Solutions/Cards',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Ragini GOWRISUNKUR',
    position: 'Head Custody',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Christophe Bernard PANHARD',
    position: 'Head External Asset Managers',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Linita Jyoti Sharma KIM CURRUN',
    position: 'Head Service Excellence',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Ragnish GUJJALU',
    position: 'Head Projects',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Ashwin Kumar RAMPHUL',
    position: 'Chief Information Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Neelesh Sharma SAWOKY',
    position: 'Head Internal Audit and Investigation',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mrs Latasha BISSESSUR',
    position: 'Head Compliance',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mr Ravi GUNESS',
    position: 'Senior Officer, Recovery',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const BOFboardData = [
  {
    name: 'Alphonse RALISON',
    position: 'Chairman',
    memberSince: '',
    previousPosition: '',
    education: "",
  },
  {
    name: 'Amine BOUABID',
    position: 'BOA GROUP S.A. Representative',
    memberSince: '',
    previousPosition: '',
    education: "",
  },
  {
    name: 'BOA GROUP S.A.',
    position: '',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Jean François MONTEIL',
    position: '',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Vololomanitra RAKOTONDRALAMBO',
    position: '',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Patrick RAZAFINDRAFITO',
    position: '',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Abderrazzak ZEBDANI ',
    position: '',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Tsirofy Mihamina RATOVOHARINONY',
    position: 'Madgasacar  State Representative',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
];

const BOFexecutiveData = [
  {
    name: 'Mr. Othmane ALAOUI, ',
    position: 'General Manager',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const DirectorsTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [executiveData, setExecutiveData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  useEffect(() => {
    if (state?.name === 'FIRST NATIONAL BANK OF ESWATINI LIMITED') {
      setBoardData(FNBboardData);
      setExecutiveData(FNBexecutiveData);
    } else if (state?.name === 'ABC HOLDINGS LIMITED') {
      setExecutiveData(ABCexecutiveData);
      setBoardData(ABCboardData);
    } else if (
      state?.name ===
      'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ'
    ) {
      setExecutiveData(IMBexecutiveData);
      setBoardData(IMBboardData);
    } else if (state?.name === 'LETSHOGO HOLDING') {
      setExecutiveData(LETexecutiveData);
      setBoardData(LETboardData);
    } else if (
      state?.name === 'BANQUE SBM MADAGASCAR'
    ) {
      setExecutiveData(MCBexecutiveData);
      setBoardData(MCBboardData);
    }  else if (
      state?.name === 'BANK OF AFRICA'
    ) {
      setExecutiveData(BOFexecutiveData);
      setBoardData(BOFboardData);
    }
  }, [state]);
  return (
    <Grid container spacing={2} mt={5}>
      <Stack>
        <Box display="flex" mt={2} gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Country :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedCountry?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedCountry?.displayName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Bank :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedBank?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedBank?.bankName}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {/* Board of Directors Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden', mb: 4 }}
        >
          <Table aria-label="board of directors table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Board of Directors
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
          <HeadTableCell sx={{ width: '120px' }}>Name</HeadTableCell>
          <HeadTableCell sx={{ width: '160px' }}>Board Position</HeadTableCell>
          <HeadTableCell sx={{ width: '120px' }}>Board Member since (dd/mm/yy)</HeadTableCell>
          <HeadTableCell sx={{ width: '300px' }}>
                  Other Current / Previous Positions
                </HeadTableCell>
          <HeadTableCell sx={{ width: '300px' }}>Education / Other Qualification</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boardData.map((row, index) => (
                <TableRow key={index}>
            <StyledTableCell sx={{ width: '120px' }}>{row.name}</StyledTableCell>
            <StyledTableCell sx={{ width: '160px' }}>{row.position}</StyledTableCell>
            <StyledTableCell sx={{ width: '120px' }}>{row.memberSince}</StyledTableCell>
            <StyledTableCell sx={{ width: '300px' }}>{row.previousPosition}</StyledTableCell>
            <StyledTableCell sx={{ width: '300px' }}>{row.education}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Executive Management Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="executive management table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Executive Management
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
          <HeadTableCell sx={{ width: '120px' }}>Name</HeadTableCell>
          <HeadTableCell sx={{ width: '160px' }}>Position</HeadTableCell>
          <HeadTableCell sx={{ width: '120px' }}>
                  Start Date of Current Position (dd/mm/yy)
                </HeadTableCell>
          <HeadTableCell sx={{ width: '300px' }}>
            Previous Executive / Senior Management Positions
          </HeadTableCell>
          <HeadTableCell sx={{ width: '300px' }}>Education / Other Qualification</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {executiveData.map((row, index) => (
                <TableRow key={index}>
            <StyledTableCell sx={{ width: '130px' }}>{row.name}</StyledTableCell>
            <StyledTableCell sx={{ width: '160px' }}>{row.position}</StyledTableCell>
            <StyledTableCell sx={{ width: '120px' }}>{row.startDate}</StyledTableCell>
            <StyledTableCell sx={{ width: '300px' }}>{row.previousPosition}</StyledTableCell>
            <StyledTableCell sx={{ width: '300px' }}>{row.education}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-loan-breakdown', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              // endIcon={<ArrowForwardIcon />}
            >
              Done
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DirectorsTable;
