import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

// Import the image directly
import AbcBank from '../../assets/abc.png';
import Botswana from '../../assets/botswana.png';
import Eswatini from '../../assets/eswatini.png';
import FNBBank from '../../assets/fnbBank.jpeg';
import Namibia from '../../assets/namibia.png';
import Seychelles from '../../assets/seychelles.png';
import Madagascar from '../../assets/Flag-Madagascar.jpg';
import IMB from '../../assets/imb.jpg';
import MCB from '../../assets/mcb.png';
import LETSHEGO from '../../assets/LETSHEGO.jpg';
import SMB from '../../assets/smb.png';
import BOF from '../../assets/Bof.png';
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

// Full bank list
const bankList = [
  {
    bankName: 'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ',
    displayName: 'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ',
    icon: IMB,
    country: 'seychelles',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12,

        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        afreximbankBenchmark: '>=10%',
        y1: 2743528,
        y2: 2839766,
        y3: '19%',
        y4: '19%',
        y5: 287446,
        y6: 448540,
        y7: 'N/A',
        y8: '19.36%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        afreximbankBenchmark: '<=5%',
        y1: 36,
        y2: 89381,
        y3: '0%',
        y4: '3%',
        y5: 105156,
        y6: 182448,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        afreximbankBenchmark: '>=100',
        y1: 16268,
        y2: 41415,
        y3: '45189%',
        y4: '46%',
        y5: 181970,
        y6: 200587,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
          afreximbankBenchmark: '>=8%',
        y1: 841579,
        y2: 1042661,
        y3: '9.37%',
        y4: '10.55%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel: ' Total Assets ',
        tooltip: 'Net Interest Income/ Total Assets ',
        afreximbankBenchmark: '>=10%',
        y1: 26858,
        y2: 36546,
        y3: '27.41%',
        y4: '34.39%',
        y5: 11193381,
        y6: 12367099,
        y7: '26.6%',
        y8: 'N/A',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: ' Average banking Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        afreximbankBenchmark: '>=1%',
        y1: 841579 + 26858,
        y2: 1079207,
        y3: '3%',
        y4: '4%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        afreximbankBenchmark: '<=65%',
        y1: 4485097,
        y2: 5757026,
        y3: '22.40%',
        y4: '28.11%',
        y5: 9971765,
        y6: 10913951,
        y7: 'N/A',
        y8: '26.5%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
          afreximbankBenchmark: '<=80%',
        y1: '97%',
        y2: '97%',
        y3: '0.28',
        y4: '0.26',
        y5: 4398740,
        y6: 4043922,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '91%',
        y2: '90%',
        y3: '43.36%',
        y4: '36.55%',
        y5: 10144826,
        y6: 11062670,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        y1: '53%',
        y2: '50%',
        y3: '44%',
        y4: '37%',
        y5: 2214504,
        y6: 1863826,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 1048555,
        y6: 1304429,
      },
    ],
  },
  {
    bankName: 'FIRST NATIONAL BANK OF ESWATINI LIMITED',
    displayName: 'FIRST NATIONAL BANK OF ESWATINI LIMITED',
    icon: FNBBank,
    country: 'eswatini',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 8,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        y1: 2996970,
        y2: 3635599,
        y3: '25%',
        y4: '21%',
        y5: 341637,
        y6: 449191,
        y7: '24.78%',
        y8: 'N/A',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        y1: 105571,
        y2: 107995,
        y3: '4%',
        y4: '3%',
        y5: 479803,
        y6: 542395,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=5%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 158769,
        y2: 149688,
        y3: '150%',
        y4: '139%',
        y5: 428087,
        y6: 464813,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=100',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
        y1: 1039478,
        y2: 1120472,
        y3: '12.54%',
        y4: '13.89%',
        y5: '23%',
        y6: '20%',
        y7: '13%',
        y8: 'N/A',
        afreximbankBenchmark: '>=8%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel: ' Total Assets ',
        tooltip: 'Net Interest Income/ Total Assets ',
        y1: 42526,
        y2: 48911,
        y3: '44.35%',
        y4: '46.39%',
        y5: 8623686,
        y6: 8414515,
        y7: 'N/A',
        y8: '20.3%',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: ' Average banking Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        y1: 1039478 + 42526,
        y2: 1120472 + 48911,
        y3: '6%',
        y4: '6%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=1%',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        y1: 4366441,
        y2: 5454212,
        y3: '82.22%',
        y4: '85.70%',
        y5: 6586885,
        y6: 5171237,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=65%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '96%',
        y2: '96%',
        y3: '0.45%',
        y4: '0.70%',
        y5: 2714361,
        y6: 1141595,
        y7: '49.3%',
        y8: '57.0',
        afreximbankBenchmark: '<=80%',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '36%',
        y2: '31%',
        y3: '35.99%',
        y4: '15.76%',
        y5: 7541913,
        y6: 7245361,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        y1: '51%',
        y2: '88%',
        y3: '0.41',
        y4: '0.22',
        y5: 2695858,
        y6: 1129540,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: 'Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 1081773,
        y6: 1169154,
      },
    ],
  },
  {
    bankName: 'ABC HOLDINGS LIMITED',
    displayName: 'ABC HOLDINGS LIMITED',
    icon: AbcBank,
    country: 'botswana',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12.5,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        y1: 6820608,
        y2: 6362917,
        y3: '20%',
        y4: '22%',
        y5: 399167,
        y6: 408768,
        y7: '19.00%',
        y8: '19.92%',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        y1: 303449,
        y2: 292263,
        y3: '4%',
        y4: '5%',
        y5: 504926,
        y6: 545820,
        y7: '3.11%',
        y8: '3.63%',
        afreximbankBenchmark: '<=5%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 243051,
        y2: 232020,
        y3: '80%',
        y4: '79%',
        y5: 152304,
        y6: 204028,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=100',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
        y1: 921840,
        y2: 996639,
        y3: '10.72%',
        y4: '10.57%',
        y5: '20%',
        y6: '22%',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=8%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel: ' Total Assets ',
        tooltip: 'Net Interest Income/ Total Assets ',
        y1: 496356,
        y2: 451161,
        y3: '40%',
        y4: '39.24%',
        y5: 9308248,
        y6: 9850811,
        y7: '22.9%',
        y8: '23.1%',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: ' Average banking Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        y1: 921840 + 496356,
        y2: 996639 + 451161,
        y3: '4%',
        y4: '4%',
        y5: 3653947,
        y6: 3823119,
        y7: '1.83',
        y8: '1.87',
        afreximbankBenchmark: '>=1%',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        y1: 6953331,
        y2: 6651776,
        y3: '91.56%',
        y4: '89.07%',
        y5: 7468283,
        y6: 7748789,
        y7: '58.3%',
        y8: '52.8%',
        afreximbankBenchmark: '<=65%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '65%',
        y2: '69%',
        y3: '0.91%',
        y4: '0.82%',
        y5: 2335596,
        y6: 3225108,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=80%',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '84.8%',
        y2: '85.4%',
        y3: '28.11%',
        y4: '36.61%',
        y5: 8310228,
        y6: 8809229,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        y1: '40.2%',
        y2: '44.6%',
        y3: '31%',
        y4: '42%',
        y5: 1438425,
        y6: 2080879,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 998020,
        y6: 1041582,
      },
    ],
  },
  {
    bankName: 'LETSHOGO HOLDING',
    displayName: 'LETSHOGO HOLDING',
    icon: LETSHEGO,
    country: 'namibia',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        y1: 4801245,
        y2: 4783462,
        y3: '54%',
        y4: '45%',
        y5: 453426,
        y6: 438209,
        y7: 'N/A',
        y8: 'N/A',
        limit: 12,
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        y1: 237227,
        y2: 302601,
        y3: '5%',
        y4: '6%',
        y5: 268334,
        y6: 262301,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=5%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 48543,
        y2: 43155,
        y3: '20%',
        y4: '14%',
        y5: 52721,
        y6: 38143,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=100',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
        y1: 2709946,
        y2: 2633193,
        y3: '46.38%',
        y4: '40.37%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=8%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel: ' Total Assets ',
        tooltip: 'Net Interest Income/ Total Assets ',
        y1: 130692,
        y2: -13074,
        y3: '16.27%',
        y4: '15.97%',
        y5: 6007163,
        y6: 6796373,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: ' Average banking Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        y1: 2840638,
        y2: 2620119,
        y3: '8%',
        y4: '6%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=1%',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        y1: 5220181,
        y2: 5767768,
        y3: '53.02%',
        y4: '55.06%',
        y5: 535687,
        y6: 827978,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=65%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '95%',
        y2: '100%',
        y3: '8.96',
        y4: '5.78',
        y5: 969525,
        y6: 1663923,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=80%',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '53%',
        y2: '51%',
        y3: '30.10%',
        y4: '41.06%',
        y5: 3221030,
        y6: 4052839,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        y1: '57%',
        y2: '51%',
        y3: '1.81',
        y4: '2.01',
        y5: 320815,
        y6: 750849,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 2786133,
        y6: 2743534,
      },
    ],
  },
  {
    bankName: 'BANQUE SBM MADAGASCAR',
    displayName: 'BANQUE SBM MADAGASCAR',
    icon: SMB,
    country: 'madagascar',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 8,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        y1: 118545,
        y2: 119070,
        y3: '17%',
        y4: '19%',
        y5: 6930,
        y6: 8492,
        y7: 'N/A',
        y8: '19.2%',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        y1: 9360,
        y2: 6245,
        y3: '8%',
        y4: '5%',
        y5: 2687,
        y6: 3457,
        y7: '3.7%',
        y8: 'N/A',
        afreximbankBenchmark: '<=5%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 10426,
        y2: 6952,
        y3: '111%',
        y4: '111%',
        y5: 2454,
        y6: 2904,
        y7: 'N/A',
        y8: '0.86%',
        afreximbankBenchmark: '>=100',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel: ' Total Assets ',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
        y1: 18439,
        y2: 22136,
        y3: '8.07%',
        y4: '9.15%',
        y5: 269074,
        y6: 281113,
        y7: 'N/A',
        y8: '11.2%',
        afreximbankBenchmark: '>=8%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel:
          '  Customer Deposits (includes institutional and intergroup deposits)',
        tooltip: 'Net Interest Income/ Total Assets ',
        y1: 1886,
        y2: 1855,
        y3: '31.93%',
        y4: '33.01%',
        y5: 236885,
        y6: 239208,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: 'High Liquidity Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        y1: 20325,
        y2: 23991,
        y3: '3%',
        y4: '3%',
        y5: 147970,
        y6: 159147,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=1%',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        y1: 120360,
        y2: 123981,
        y3: '28.63%',
        y4: '30.34%',
        y5: 247370,
        y6: 255385,
        y7: '38.3%',
        y8: '35.4%',
        afreximbankBenchmark: '<=65%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '91%',
        y2: '92%',
        y3: '50%',
        y4: '50%',
        y5: 26534,
        y6: 18837,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=80%',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel: ' Total Capital (shareholders equity)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '46%',
        y2: '46%',
        y3: '59.82%',
        y4: '62.32%',
        y5: 21704,
        y6: 25728,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        calculationLabel: '',
        y1: '52%',
        y2: '51%',
        y3: '0.62',
        y4: '0.67',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
    ],
  },
  {
    bankName: 'BANK OF AFRICA',
    displayName: 'BANK OF AFRICA',
    icon: BOF,
    country: 'madagascar',
    header: [2021, 2022],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 8,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: 'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        y1: 2024165,
        y2: 2419277,
        y3: '10.00%',
        y4: '10.25%',
        y5: 172879,
        y6: 190534,
        y7: 'N/A',
        y8: '19.2%',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a2',
        resultLabel: 'Non-Performing Loan Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: 'NPLs/ Total Loans',
        y1: '',
        y2: '',
        y3: '0%',
        y4: '0%',
        y5: 142751,
        y6: 158937,
        y7: '3.7%',
        y8: 'N/A',
        afreximbankBenchmark: '<=5%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: '0.86%',
        afreximbankBenchmark: '>=100',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel: ' Total Assets ',
        tooltip:
          'Total Capital (shareholders equity)/ Average banking Assets',
        y1: 227920,
        y2: 259035,
        y3: '10.01%',
        y4: '9.98%',
        y5: 3606175,
        y6: 4070971,
        y7: 'N/A',
        y8: '11.2%',
        afreximbankBenchmark: '>=8%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity (%)',
        calculationLabel:
          '  Customer Deposits (includes institutional and intergroup deposits)',
        tooltip: 'Net Interest Income/ Total Assets ',
        y1: '-',
        y2: '-',
        y3: '47.88%',
        y4: '46.90%',
        y5: 2862481,
        y6: 3080220,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=10%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (%)',
        calculationLabel: 'High Liquidity Assets',
        tooltip: 'Net interest Income/ Total Capital (shareholders equity)',
        y1: 227920,
        y2: 259035,
        y3: '4.8%',
        y4: '4.7%',
        y5: 111312,
        y6: 162157,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=1%',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income (%)',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          'Operating Expenses/ (Net Interest Income+ Non-Interest Income) (excludes loss on sale of assets)',
        y1: 2280240,
        y2: 2527515,
        y3: '82.57%',
        y4: '83.42%',
        y5: 3245112,
        y6: 162157,
        y7: '38.3%',
        y8: '35.4%',
        afreximbankBenchmark: '<=65%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit (%) ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          'Total Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '100%',
        y2: '100%',
        y3: '71%',
        y4: '79%',
        y5: 111312,
        y6: 162157,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '<=80%',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities (%) ',
        calculationLabel: ' Total Capital (shareholders equity)',
        tooltip:
          'High Liquidity Assets/ Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '',
        y2: '',
        y3: '3.43%',
        y4: '4.42%',
        y5: 361063,
        y6: 406270,
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>=15%',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits (%) ',
        tooltip:
          '(Cash and Cash Equivalents) / Total Customer Deposits (including institutional and intergroup deposits)',
        calculationLabel: '',
        y1: '',
        y2: '',
        y3: '0.04',
        y4: '0.05',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
        afreximbankBenchmark: '>40%',
      },
    ],
  },
];

// Full country list with currency information
const selectCountry = [
  // {
  //   bankName: 'nigeria',
  //   displayName: 'Nigeria',
  //   icon: NigeriaFlag,
  //   currencySymbol: '₦',
  //   currencyName: 'NGN',
  //   enabled: true,
  // },
  {
    bankName: 'seychelles',
    displayName: 'Seychelles',
    icon: Seychelles,
    currencySymbol: '₨',
    currencyName: 'SCR',
    enabled: true,
    region: 'east-africa',
  },
  {
    bankName: 'namibia',
    displayName: 'Namibia',
    icon: Namibia,
    currencySymbol: '$',
    currencyName: 'NAD',
    enabled: true,
    region: 'southern-africa',
  },
  {
    bankName: 'botswana',
    displayName: 'Botswana',
    icon: Botswana,
    currencySymbol: 'P',
    currencyName: 'BWP',
    enabled: true,
    region: 'southern-africa',
  },
  {
    bankName: 'eswatini',
    displayName: 'Eswatini',
    icon: Eswatini,
    currencySymbol: 'L',
    currencyName: 'SZL',
    enabled: true,
    region: 'southern-africa',
  },
  {
    bankName: 'madagascar',
    displayName: 'Madagascar',
    icon: Madagascar,
    currencySymbol: 'M',
    currencyName: 'MGA',
    enabled: true,
    region: 'east-africa',
  },
];

const selectRegion = [
  { displayName: 'Southern Africa', value: 'southern-africa' },
  { displayName: 'East Africa', value: 'east-africa' },
  { displayName: 'West Africa', value: 'west-africa' },
  { displayName: 'North Africa', value: 'north-africa' },
  { displayName: 'Coricom', value: 'caricom' },
];

const tableRows = [
  {
    id: 'a1',
    label: 'Gross Loans',
  },
  {
    id: 'a2',
    label: 'Non-Performing Loans',
  },
  {
    id: 'a3',
    label: 'Provision for Loan Loss ',
  },
  {
    id: 'a4',
    label: 'Tier 1 Capital',
  },
  {
    id: 'a5',
    label: 'Tier 2 Capital',
  },
  {
    id: 'a6',
    label: 'Total Capital',
  },
  {
    id: 'a7',
    label: 'Total Risk-weighted Assets',
  },
  {
    id: 'a8',
    label: 'Tier 1 Capital Ratio %',
  },
  {
    id: 'a9',
    label: 'Top 20 loans Concentration level %',
  },
  {
    id: 'a10',
    label: 'Top 20 depositors concentration level %',
  },
];

const summaryData = {
  seychelles: {
    title: "CAMEL Rating Summary: 3",
    conclusion: "The Bank's CAMEL rating of 3 reflects its strong capital adequacy, effective management, improving asset quality, and increasing profitability. However, the rising cost-to-income ratio and declining liquidity are significant concerns. The Bank needs to prioritize cost efficiency and liquidity management to improve its overall stability and performance.",
    CapitalAdequacy: {
      summary: "Capital Adequacy | 2022: 19.4% | 2023: 18.7%",
      toolkit: [
        "Evaluate the Bank's capital buffers to absorb potential losses.",
        "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
        "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
      ],
      findings: "The Bank's capital adequacy ratios are commendable, standing well above the Central Bank's minimum requirement of 12% and the Basel III benchmark of 10.5%. These robust capital levels indicate a strong buffer against potential losses and support long-term financial stability.",
      indicator: "green"
    },
    AssetQuality: {
      summary: "Asset Quality | Non-Performing Loans (NPL) Ratio: | 2022: 0% | 2023: 3%",
      toolkit: [
        "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
        "Assess risk management practices related to credit risk, market risk, and operational risk.",
        "Analyze provisioning for bad loans."
      ],
      findings: " The Bank achieved a significant improvement in asset quality in 2022, reducing the NPL ratio to 0%. However, there was a modest increase to 3% in 2023. Despite this rise, the asset quality remains manageable, demonstrating effective recovery and risk management practices.",
      indicator: "green"
    },
    ManagementQuality: {
      summary: "Management Quality | Evaluation: The management team is highly experienced and competent, evidenced by their strategic vision and operational execution. ",
      toolkit: [
        "Evaluate the competency and governance practices of the management team.",
        "Assess adherence to regulatory and ethical standards.",
        "Examine strategic planning and risk management frameworks."
      ],
      findings: "The management's extensive experience and strategic initiatives have been pivotal in driving the Bank's performance. Their leadership has effectively navigated the Bank through various challenges, ensuring stability and growth.",
      indicator: "green"
    },
    Earnings: {
      summary: "Earnings | Return on Assets (ROA) | 2022: 3% | 2023: 4% |Cost to Income Ratio: | 2022: 22.4%| 2023: 28.11%",
      toolkit: [
        "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
        "Evaluate trends in net interest margin (NIM) and non-interest income.",
        "Review earnings sustainability and quality of revenue sources."
      ],
      findings: " The Bank has demonstrated an increase in profitability, with the ROA improving from 5.6% in 2022 to 6.4% in 2023.  However, the high cost-to-income ratio, despite its decline from 89.22% to 85.70%, indicates that operational costs remain a challenge. Continued focus on cost management is necessary to enhance profitability further. ",
      indicator: "amber"
    },
    Liquidity: {
      summary: "Liquidity | Liquid Assets to Net Liabilities: | 2022: 43.36% |  2023: 36.55%",
      toolkit: [
        "Assess the Bank's ability to meet short-term and long-term obligations.",
        "Review liquidity ratios and the adequacy of liquid asset reserves.",
        "Consider market conditions and the Bank's access to funding."
      ],
      findings: "The Bank's liquidity position has declined, with liquid assets to net liabilities ratios dropping from 43.36% in 2022 to 36.55% in 2023. This reduction highlights potential liquidity challenges, and the Bank needs to focus on enhancing its liquidity management to ensure it can meet its short-term obligations effectively. ",
      indicator: "amber"
    },
    titleTooltip: "",
  },
  namibia: {
    title: "CAMEL Rating Summary: 2",
    conclusion: "The Bank's CAMEL rating of 2 reflects its excellent capital adequacy, effective management, and improved liquidity. However, the slight decline in asset quality and profitability are areas of concern. To enhance its overall performance, the Bank needs to focus on strengthening its credit risk management and improving cost efficiency.",
    CapitalAdequacy: {
      summary: "Capital Adequacy | 2022: 54% | 2023: 45%",
      toolkit: [
        "Evaluate the Bank's capital buffers to absorb potential losses.",
        "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
        "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
      ],
      findings: "The Bank's capital adequacy ratios are exceptionally strong, significantly exceeding the Central Bank's minimum requirement of 12% and the Basel III threshold of 10.5%. This high level of capital adequacy provides a substantial buffer against potential losses, ensuring the Bank's financial stability and resilience.​",
      indicator: "green"
    },
    AssetQuality: {
      summary: "Asset Quality | Non-Performing Loans (NPL) Ratio: | 2022: 5% | 2023: 6%",
      toolkit: [
        "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
        "Assess risk management practices related to credit risk, market risk, and operational risk.",
        "Analyze provisioning for bad loans."
      ],
      findings: " The Bank's asset quality has declined, with the NPL ratio increasing from 5% in 2022 to 6% in 2023. This uptick suggests a rise in non-performing loans, which may pose a risk if not managed properly. It indicates a need for strengthened credit risk management practices to curb further deterioration.​",
      indicator: "amber"
    },
    ManagementQuality: {
      summary: "Management Quality | Evaluation: The management team is experienced and competent, demonstrating effective oversight and strategic decision-making.",
      toolkit: [
        "Evaluate the competency and governance practices of the management team.",
        "Assess adherence to regulatory and ethical standards.",
        "Examine strategic planning and risk management frameworks."
      ],
      findings: " The management’s extensive experience and strategic initiatives have been instrumental in navigating the Bank through various challenges, maintaining operational stability, and driving growth. Their leadership continues to be a vital asset for the Bank.",
      indicator: "green"
    },
    Earnings: {
      summary: "Earnings | Return on Assets (ROA) | 2022: 8% | 2023: 6% | Cost to Income Ratio: | 2022: 53.02% | 2023: 55.06%",
      toolkit: [
        "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
        "Evaluate trends in net interest margin (NIM) and non-interest income.",
        "Review earnings sustainability and quality of revenue sources."
      ],
      findings: "The Bank has experienced a decline in profitability, with ROA decreasing from 8% in 2022 to 6% in 2023. This decline is likely due to rising operational costs, as indicated by the cost-to-income ratio increasing from 53.02% to 55.06%. Improving cost efficiency will be crucial for enhancing profitability in the future.  ",
      indicator: "amber"
    },
    
    Liquidity: {
      summary: "Liquidity | Liquid Assets to Net Liabilities: | 2022: 30.10% | 2023: 41.06%",
      toolkit: [
        "Assess the Bank's ability to meet short-term and long-term obligations.",
        "Review liquidity ratios and the adequacy of liquid asset reserves.",
        "Consider market conditions and the Bank's access to funding."
      ],
      findings: "The Bank's liquidity position has improved, with the liquid assets to net liabilities ratio increasing from 30.10% in 2022 to 41.06% in 2023. This improvement indicates a stronger capacity to meet short-term obligations, enhancing the Bank's overall liquidity management. ",
      indicator: "green"
    },
    titleTooltip: "",
  },
  botswana: {
    title: "CAMEL Rating Summary: 3",
    conclusion: "The Bank's CAMEL rating of 3 reflects its excellent capital adequacy, effective management, and improved liquidity. However, the decline in asset quality and profitability are areas of concern that need to be addressed. To enhance its overall performance, the bank needs to focus on strengthening its credit risk management and improving cost efficiency.",
    CapitalAdequacy: {
      summary: "Capital Adequacy | 2022: 20% | 2023: 22%",
      toolkit: [
        "Evaluate the Bank's capital buffers to absorb potential losses.",
        "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
        "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
      ],
      findings: " The Bank's capital adequacy ratios are impressive, significantly exceeding the Central Bank's minimum requirement of 12.5% and the Basel III threshold of 10.5%. These strong capital levels ensure a substantial buffer against potential losses, supporting the Bank's financial stability and resilience.",
      indicator: "green"
    },
    AssetQuality: {
      summary: "Asset Quality | Non-Performing Loans (NPL) Ratio: | 2022: 4% | 2023: 5%",
      toolkit: [
        "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
        "Assess risk management practices related to credit risk, market risk, and operational risk.",
        "Analyze provisioning for bad loans."
      ],
      findings: "The Bank's asset quality has declined, with the NPL ratio increasing from 4% in 2022 to 5% in 2023. This rise suggests a growing proportion of non-performing loans, which could signal potential risks if not managed effectively. Strengthening credit risk management practices will be crucial to curb further deterioration.",
      indicator: "amber"
    },
    ManagementQuality: {
      summary: "Management Quality | Evalution: The management team is experienced and competent, demonstrating effective oversight and strategic decision-making.",
      toolkit: [
        "Evaluate the competency and governance practices of the management team.",
        "Assess adherence to regulatory and ethical standards.",
        "Examine strategic planning and risk management frameworks."
      ],
      findings: "The management’s extensive experience and strategic initiatives have been instrumental in maintaining the Bank’s operations and navigating challenges, contributing to overall stability and performance.",
      indicator: "green"
    },
    Earnings: {
      summary: "Earnings | Return on Assets (ROA) | 2022: 4.3% | 2023: 4.1% | Cost to Income Ratio: | 2022: 92% | 2023: 89%",
      toolkit: [
        "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
        "Evaluate trends in net interest margin (NIM) and non-interest income.",
        "Review earnings sustainability and quality of revenue sources."
      ],
      findings: " The Bank has experienced a slight decline in profitability, with ROA decreasing from 4.3% in 2022 to 4.1% in 2023. Although there is an improvement in cost efficiency, as seen in the reduction of the cost-to-income ratio from 92% to 89%, the ratio remains high, indicating the need for continued focus on cost management. ",
      indicator: "amber"
    },
    Liquidity: {
      summary: "Liquidity | Liquid Assets to Net Liabilities: | 2022: 28.11% | 2023: 36.61%",
      toolkit: [
        "Assess the Bank's ability to meet short-term and long-term obligations.",
        "Review liquidity ratios and the adequacy of liquid asset reserves.",
        "Consider market conditions and the Bank's access to funding."
      ],
      findings: "The Bank's liquidity position has improved, with the liquid assets to net liabilities ratio increasing from 28.11% in 2022 to 36.61% in 2023. This improvement indicates a stronger ability to meet short-term obligations, enhancing the Bank's overall liquidity management. ",
      indicator: "green"
    },
    titleTooltip: "",
  },
  eswatini: {
    title: "CAMEL Rating Summary: 3",
    conclusion: "The Bank's CAMEL rating of 3 reflects strong capital adequacy, improving asset quality, competent management, and increasing profitability. However, the high cost-to-income ratio and declining liquidity are significant concerns. The Bank needs to focus on improving cost efficiency and liquidity management to enhance its overall performance and stability.",
    CapitalAdequacy: {
      summary: "Capital Adequacy | 2022: 24.78% | 2023: 21.44% ",
      toolkit: [
        "Evaluate the Bank's capital buffers to absorb potential losses.",
        "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
        "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
      ],
      findings: " The Bank's capital adequacy ratios are significantly above the regulatory minimum of 8% set by the Central Bank and the Basel III requirement of 10.5%. These figures indicate a robust capital base, providing a strong buffer against potential losses and ensuring financial stability.",
      indicator: "green"
    },
    AssetQuality: {
      summary: "Asset Quality | Non-Performing Loans (NPL) Ratio: | 2022: 4% | 2023: 3%",
      toolkit: [
        "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
        "Assess risk management practices related to credit risk, market risk, and operational risk.",
        "Analyze provisioning for bad loans."
      ],
      findings: "There has been a noticeable improvement in asset quality, reflected by the reduction in the NPL ratio from 4% in 2022 to 3% in 2023.  This decline signifies effective credit risk management and a healthier loan portfolio, reducing the potential for loan defaults.",
      indicator: "green"
    },
    ManagementQuality: {
      summary: "Management Quality | Evaluation: The management team is competent and experienced, which has been instrumental in achieving the Bank’s strategic and operational goals.",
      toolkit: [
        "Evaluate the competency and governance practices of the management team.",
        "Assess adherence to regulatory and ethical standards.",
        "Examine strategic planning and risk management frameworks."
      ],
      findings: " The management's body of work and experience have been pivotal in steering the Bank toward consistent performance. Their strategic initiatives have contributed to the Bank's overall stability and growth.",
      indicator: "green"
    },
    Earnings: {
      summary: "Earnings | Return on Assets (ROA): | 2022: 5.6% | 2023: 6.4% | Cost to Income Ratio: | 2022: 89.22% | 2023: 85.70%",
      toolkit: [
        "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
        "Evaluate trends in net interest margin (NIM) and non-interest income.",
        "Review earnings sustainability and quality of revenue sources."
      ],
      findings: "The Bank has demonstrated an increase in profitability, with the ROA improving from 5.6% in 2022 to 6.4% in 2023.  However, the high cost-to-income ratio, despite its decline from 89.22% to 85.70%, indicates that operational costs remain a challenge. Continued focus on cost management is necessary to enhance profitability further. ",
      indicator: "amber"
    },
    Liquidity: {
      summary: "Liquidity | Liquid Assets to Net Liabilities: | 2022: 35.99% | 2023: 15.76%",
      toolkit: [
        "Assess the Bank's ability to meet short-term and long-term obligations.",
        "Review liquidity ratios and the adequacy of liquid asset reserves.",
        "Consider market conditions and the Bank's access to funding."
      ],
      findings: "The Bank's liquidity position has significantly declined, with liquid assets to net liabilities ratios dropping from 35.99% in 2022 to 15.76% in 2023. This indicates potential liquidity challenges, and the Bank needs to take measures to improve its short-term liquidity to ensure it can meet its obligations. ",
      indicator: "amber"
    },
    titleTooltip: "",
  },
  madagascar: {
    bank1: {
      title: "CAMEL Rating Summary: 2",
      conclusion: "The Bank's CAMEL rating of 2 reflects its strong capital adequacy, improving asset quality, competent management, increasing profitability, and solid liquidity. However, there are areas to watch, particularly the rising operational costs, which could impact future profitability if not managed effectively.",
      CapitalAdequacy: {
        summary: "Capital Adequacy | 2022: 17% | 2023: 19%",
        toolkit: [
          "Evaluate the Bank's capital buffers to absorb potential losses.",
          "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
          "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
        ],
        findings: "The Bank's capital adequacy ratios are significantly above the regulatory minimum of 8% set by the Central Bank and the Basel III requirement of 10.5%. This indicates a robust capital base, which provides a strong cushion against potential losses and supports sustained growth.",
        indicator: "green"
      },
      AssetQuality: {
        summary: "Asset Quality | Non-Performing Loans (NPL) Ratio: | 2022: 8% | 2023: 5%",
        toolkit: [
          "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
          "Assess risk management practices related to credit risk, market risk, and operational risk.",
          "Analyze provisioning for bad loans."
        ],
        findings: "There has been a marked improvement in asset quality, as evidenced by the reduction in the NPL ratio from 8% in 2022 to 5% in 2023. This decline signifies better credit risk management and a healthier loan portfolio.",
        indicator: "green"
      },
      ManagementQuality: {
        summary: "Management Quality | Evaluation: The management team has demonstrated competence and effectiveness, as reflected in their strategic initiatives and operational improvements.",
        toolkit: [
          "Evaluate the competency and governance practices of the management team.",
          "Assess adherence to regulatory and ethical standards.",
          "Examine strategic planning and risk management frameworks."
        ],
        findings: "The management’s extensive experience and body of work have been instrumental in driving the Bank’s positive performance metrics.",
        indicator: "green"
      },
      Earnings: {
        summary: "Earnings | Return on Assets (ROA): | 2022: 2.6% | 2023: 3% | Cost to Income Ratio: | 2022: 28.63% | 2023: 30.34%",
        toolkit: [
          "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
          "Evaluate trends in net interest margin (NIM) and non-interest income.",
          "Review earnings sustainability and quality of revenue sources."
        ],
        findings: "The Bank has shown a slight increase in profitability, with the ROA improving from 2.6% in 2022 to 3% in 2023. This is a positive indicator, although the rising cost-to-income ratio, from 28.63% to 30.34%, suggests that operational costs are increasing at a higher rate, which should be monitored closely.",
        indicator: "amber"
      },
      Liquidity: {
        summary: "Liquidity | Liquid Assets to Net Liabilities: | 2022: 59.82% | 2023: 62.32%",
        toolkit: [
          "Assess the Bank's ability to meet short-term and long-term obligations.",
          "Review liquidity ratios and the adequacy of liquid asset reserves.",
          "Consider market conditions and the Bank's access to funding."
        ],
        findings: "The Bank's liquidity position is strong, with liquid assets to net liabilities ratios of 59.82% in 2022 and 62.32% in 2023. These figures indicate a robust ability to meet short-term obligations without significant stress.",
        indicator: "green"
      },
      titleTooltip: "123",
    },
    bank2: {
      title: "CAMEL Rating Summary: 3",
      conclusion: "The Bank's CAMEL rating of 3 reflects good capital levels and effective management quality. However, the slight decline in profitability and the absence of data on asset quality are areas of concern that need to be addressed. Despite these challenges, the bank maintains a liquid position, which supports its overall stability. To enhance its performance further, the bank should focus on improving cost efficiency and providing comprehensive data on asset quality.",
      CapitalAdequacy: {
        summary: "Capital Adequacy | 2021: 10% | 2022: 10%",
        toolkit: [
          "Evaluate the Bank's capital buffers to absorb potential losses.",
          "Review regulatory compliance for minimum capital requirements under Basel III or local banking regulations.",
          "Analyze capital ratios like the Capital Adequacy Ratio (CAR)."
        ],
        findings: "The Bank's capital adequacy levels are strong, surpassing the Central Bank's minimum requirement of 8%. However, they fall slightly short of the Basel II threshold of 10.5%. Despite this, the Bank maintains a solid capital base, which is crucial for its financial stability and ability to absorb potential losses.",
        indicator: "green"
      },
      AssetQuality: {
        summary: "Asset Quality ",
        toolkit: [
          "Examine the quality of the loan portfolio and levels of Non-Performing Loans (NPLs).",
          "Assess risk management practices related to credit risk, market risk, and operational risk.",
          "Analyze provisioning for bad loans."
        ],
        findings: "Due to insufficient data, the asset quality cannot be accurately estimated. This lack of information represents a significant gap in assessing the bank's overall risk profile.",
        indicator: ""
      },
      ManagementQuality: {
        summary: "Management Quality | Evaluation: The management team is experienced and competent, demonstrating effective oversight and strategic decision-making.",
        toolkit: [
          "Evaluate the competency and governance practices of the management team.",
          "Assess adherence to regulatory and ethical standards.",
          "Examine strategic planning and risk management frameworks."
        ],
        findings: " The management’s body of work and strategic initiatives have been instrumental in navigating the bank through various challenges, maintaining operational stability, and driving growth.",
        indicator: "green"
      },
      Earnings: {
        summary: "Earnings | Return on Assets (ROA): | 2021: 4.8% | 2022: 4.7% | Cost to Income Ratio: | 2021: 82.57% | 2023: 83.42%",
        toolkit: [
          "Analyze profitability metrics like Return on Assets (ROA) and Return on Equity (ROE).",
          "Evaluate trends in net interest margin (NIM) and non-interest income.",
          "Review earnings sustainability and quality of revenue sources."
        ],
        findings: "The Bank has experienced a slight decline in profitability, with ROA decreasing from 4.8% in 2021 to 4.7% in 2022. This decrease in profitability may be due to rising operational costs, as indicated by the increase in the cost-to-income ratio from 82.57% in 2021 to 83.42% in 2023. Improved cost management strategies will be crucial for enhancing profitability.",
        indicator: "amber"
      },
      Liquidity: {
        summary: "Liquidity | Liquid Assets to Net Liabilities: | 2021: 3.43% | 2022: 4.42%",
        toolkit: [
          "Assess the Bank's ability to meet short-term and long-term obligations.",
          "Review liquidity ratios and the adequacy of liquid asset reserves.",
          "Consider market conditions and the Bank's access to funding."
        ],
        findings: " The Bank's liquidity position has improved, with the liquid assets to net liabilities ratio increasing from 3.43% in 2021 to 4.42% in 2022.This improvement indicates a stronger capacity to meet short-term obligations, enhancing the Bank's overall liquidity management. ",
        indicator: "green"
      },
      titleTooltip: "123",
    },
  }
}
const FinancialInstitution = () => {
  const navigate = useNavigate();
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for select all
  const [selectedBankLogo, setSelectedBankLogo] = useState(bankList[2]?.icon);
  const [selectedBank, setSelectedBank] = useState(bankList[2]);
  const [selectedCountryLogo, setSelectedCountryLogo] = useState(null);
  const [filteredBanks, setFilteredBanks] = useState([]); // State for filtered banks
  const [currencySymbol, setCurrencySymbol] = useState(''); // Default currency symbol
  const [currencyName, setCurrencyName] = useState(''); // Default currency name
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null); // Add this line

  // Handle row selection for checkboxes
  const handleRowClick = (index) => {
    setCheckedRows((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const isChecked = (index) => checkedRows.includes(index);

  // Handle Select All checkbox
  const handleSelectAllClick = () => {
    if (selectAll) {
      // Uncheck all rows
      setCheckedRows([]);
    } else {
      // Select all rows
      setCheckedRows(tableRows.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  const handleRegionChange = (event, value) => {
    if (!value) {
      setFilteredCountries([]);
      setSelectedCountryLogo(null);
      setCurrencySymbol('');
      setCurrencyName('');
      setSelectedBank(null);
      setSelectedBankLogo(null);
      setFilteredBanks([]);
      setSelectedCountry(null);
      return;
    }

    // Filter countries based on the selected region
    const filtered = selectCountry.filter(country => country.region === value.value);
    setFilteredCountries(filtered);
    setSelectedCountryLogo(null);
    setCurrencySymbol('');
    setCurrencyName('');
    setSelectedBank(null);
    setSelectedBankLogo(null);
    setFilteredBanks([]);
    setSelectedCountry(null); // Reset selected country
  };

  // Filter banks based on selected country
  const handleCountryChange = (e, value) => {
    if (!value) {
      setSelectedCountryLogo(null);
      setCurrencySymbol('');
      setCurrencyName('');
      setFilteredBanks([]);
      setSelectedBank(null);
      setSelectedBankLogo(null);
      setSelectedCountry(null); // Reset selected country
      return;
    }

    setSelectedCountryLogo(value.icon);
    setCurrencySymbol(value.currencySymbol); // Set the selected country's currency symbol
    setCurrencyName(value.currencyName); // Set the selected country's currency name
    setSelectedCountry(value); // Set the selected country
    // Filter banks based on the country selected
    const filtered = bankList.filter((bank) => bank.country === value.bankName);
    setFilteredBanks(filtered);
    setSelectedBank(null);
    setSelectedBankLogo(null); // Reset selected bank logo
  };

  // Get selected data from the table rows
  const selectedData = tableRows.filter((_, index) =>
    checkedRows.includes(index)
  );

  // Handle Next button click and pass selected data and currency info to the next route
  const handleNextClick = () => {
    let selectedCountry = selectCountry.find(
      (country) => country.icon === selectedCountryLogo
    );
    const allTable = bankList.find((i) => i.bankName === selectedBank.bankName);
    const data = selectedData.map((i) => {
      return {
        ...i,
        ...(allTable?.financialData?.length &&
          allTable?.financialData.find((j) => j.id === i.id)),
      };
    });

    let bankSummaryData;
    if (selectedCountry.bankName === 'madagascar') {
      if (selectedBank.bankName === 'BANQUE SBM MADAGASCAR') {
        bankSummaryData = summaryData.madagascar.bank1;
      } else if (selectedBank.bankName === 'BANK OF AFRICA') {
        bankSummaryData = summaryData.madagascar.bank2;
      }
    } else {
      bankSummaryData = summaryData[selectedCountry.bankName];
    }
    navigate('/financial-result', {
      state: {
        selectedData: data,
        header: selectedBank?.header,
        name: selectedBank?.bankName,
        currencySymbol: selectedCountry.currencySymbol,
        currencyName: selectedCountry.currencyName,
        selectedCountry,
        selectedBank,
        calculation:
          allTable?.financialData[allTable?.financialData?.length - 1],
          summaryData: bankSummaryData,
        },
    });
  };

  // Set default region and country on component load
  useEffect(() => {
    const defaultRegion = selectRegion.find(region => region.value === 'southern-africa');
    const filtered = selectCountry.filter(country => country.region === defaultRegion.value);
    setFilteredCountries(filtered);

    const defaultCountry = selectCountry.find(country => country.bankName === 'botswana');
    if (defaultCountry) {
      setSelectedCountry(defaultCountry);
      setSelectedCountryLogo(defaultCountry.icon);
      setCurrencySymbol(defaultCountry.currencySymbol);
      setCurrencyName(defaultCountry.currencyName);
      const filteredBanks = bankList.filter(bank => bank.country === defaultCountry.bankName);
      setFilteredBanks(filteredBanks);
    }
  }, []);

  return (
    <Box>
      <Grid
        container
        my={3}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        textAlign={'center'}
        spacing={3}
      >
        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Region
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-region"
                options={selectRegion}
                disableClearable
                defaultValue={selectRegion[0]}
                getOptionLabel={(option) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Region"
                    hiddenLabel
                    {...params}
                    fullWidth
                  />
                )}
                onChange={handleRegionChange}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>
        {/* Select Country Section */}
        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Country
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-country"
                options={filteredCountries}
                disableClearable
                value={selectedCountry}
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      height="48" // Ensure width and height are the same
                      style={{ borderRadius: '50%' }} // This ensures the image is round
                      src={option.icon}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Country"
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedCountryLogo && (
                            <Box
                              component="img"
                              src={selectedCountryLogo}
                              alt=""
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
                onChange={handleCountryChange}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Select Bank Section */}
        <Grid item xs={12}>
          <Typography variant="Header_semibold_48">
            Select Financial Institution
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Bank
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-bank"
                value={selectedBank || null} // Default to Nigeria
                options={filteredBanks} // Use filtered banks based on the country selected
                disableClearable
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      style={{ borderRadius: '50%' }}
                      src={option.icon}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Bank"
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedBankLogo && (
                            <Box
                              component="img"
                              src={selectedBankLogo}
                              alt=""
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
                onChange={(e, value) => {
                  setSelectedBankLogo(value.icon); // Dynamically set the bank logo based on selection
                  setSelectedBank(value); // Dynamically set the bank logo based on selection
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center">
                    Select All
                    <Checkbox
                      checked={selectAll}
                      indeterminate={
                        checkedRows.length > 0 &&
                        checkedRows.length < tableRows.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </HeadTableCell>
                  <HeadTableCell>In {currencyName} '000 </HeadTableCell>{' '}
                  {selectedBank?.header?.length &&
                    selectedBank?.header.map((item) => (
                      <>
                        <HeadTableCell align="right">{item}</HeadTableCell>{' '}
                      </>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {tableRows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { borderBottom: 0 },
                      cursor: 'pointer',
                      background: isChecked(index) ? '#042331' : 'inherit',
                    }}
                    onClick={() => handleRowClick(index)}
                  >
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={isChecked(index)}
                        onChange={() => handleRowClick(index)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.label}
                    </StyledTableCell>
                    <StyledTableCell align="right">------</StyledTableCell>
                    <StyledTableCell align="right">------</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={handleNextClick}
              disabled={!selectedData?.length}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: 'danger.light', // Change background color when disabled
                  color: 'white', // Change text color when disabled
                },
              }}
            >
              Next
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialInstitution;
