import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Switch,
  FormControlLabel,
  Menu,
  MenuItem,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import DownloadIcon from '@mui/icons-material/Download';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';
import { Document, Packer, Paragraph, Table as DocxTable, TableRow as DocxTableRow, TableCell as DocxTableCell, TextRun } from 'docx';
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#132933',
    color: '#ffffff',
    fontSize: '0.875rem',
  },
});

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FB5718', // Thumb color when checked
    '&:hover': {
      backgroundColor: 'rgba(251, 87, 24, 0.08)', // Light hover effect when checked
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FB5718', // Track color when checked
  },
}));

const FiResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(
    location.state?.selectedData || []
  );
  const [isCalculationExpanded, setCalculationExpanded] = useState(false); // State for toggle
  const [isResultExpanded, setResultExpanded] = useState(false); // State for Result toggle
  const [summaryData, setSummaryData] = useState(location.state?.summaryData || {});
  const [anchorEl, setAnchorEl] = useState(null);

   // Add Net Interest Margin row
   useEffect(() => {
    setSelectedData((prevData) => [
      ...prevData,
      {
        resultLabel: 'Net Interest Margin (%)',
        y3: '', 
        y4: '', 
        tooltip: 'Net Interest Income / Average Earning Assets',
        limit: 3, 
        afreximbankBenchmark: '<63%'
      },
    ]);
  }, []);

  // Function to format numbers with commas for display
  const formatNumberWithCommas = (value) => {
    const num = Number(String(value).replace(/,/g, ''));
    if (!isNaN(num)) {
      return num.toLocaleString('en-US');
    }
    return value;
  };

  const toggleCalculation = () => {
    setCalculationExpanded((prev) => !prev);
  };
  const toggleResult = () => {
    setResultExpanded((prev) => !prev);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadPDF = () => {
    const countryName = location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.pdf`;

    const doc = new jsPDF({ orientation: 'landscape' });
    doc.text('Financial Results', 20, 10);

    const financialTable = document.getElementById('financial-table');
    const resultTable = document.getElementById('result-table');
    const summaryTable = document.getElementById('summary-table');
    const calculationTable = document.getElementById('calculation-table');

    const headerStyles = {
        fillColor: [0, 128, 0], // RGB for green
        textColor: [255, 255, 255], // White text
        halign: 'center',
        valign: 'middle',
    };

    if (financialTable) {
        doc.autoTable({
            html: '#financial-table',
            headStyles: headerStyles,
        });
    }

    if (resultTable) {
        doc.autoTable({
            html: '#result-table',
            startY: doc.lastAutoTable.finalY + 10,
            headStyles: headerStyles,
        });
    }

    if (summaryTable) {
        const summaryData = location.state?.summaryData || {};
        const summaryRows = Object.entries(summaryData)
            .filter(([key]) => key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip')
            .map(([key, value], index) => [
                index + 1,
                value.summary,
                value.findings,
                value.indicator === 'green' ? 'Green' : value.indicator === 'amber' ? 'Amber' : value.indicator,
            ]);

        doc.autoTable({
            head: [['#', 'Summary', 'Findings', 'Indicator']],
            body: summaryRows,
            startY: doc.lastAutoTable.finalY + 10,
            headStyles: headerStyles,
        });
    }

    if (calculationTable) {
        doc.autoTable({
            html: '#calculation-table',
            startY: doc.lastAutoTable.finalY + 10,
            headStyles: headerStyles,
        });
    }

    doc.save(fileName);
    handleClose();
};

  
  const downloadCSV = () => {
    const countryName = location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.csv`;
    const currencyName = location?.state?.currencyName || "Currency";
  
    let csvContent = "data:text/csv;charset=utf-8,";
    
    // Add selected data
    csvContent += "Selected Data\n";
    csvContent += `In ${currencyName} '000,` + location.state.header.map(item => `YE ${item}`).join(",") + "\n";
    selectedData.forEach(row => {
      csvContent += `${row.label},${row.y1},${row.y2}\n`;
    });
    
    // Add result data
    csvContent += "\nResult Data\n";
    csvContent += `In ${currencyName} '000,` + location.state.header.map(item => `YE ${item}`).join(",") + ",Afreximbank benchmark,Industry benchmark YE 2022,Industry benchmark YE 2023,Comments\n";
    selectedData.forEach(row => {
      csvContent += `${row.resultLabel},${row.y3},${row.y4},${row.afreximbankBenchmark}\n`;
    });
  
    // Add summary data
  csvContent += "\nSummary Data\n";
  csvContent += "Summary,Findings,Indicator\n";
  Object.entries(summaryData).forEach(([key, value]) => {
    if (key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip') {
      csvContent += `"${value.summary}","${value.findings}",${value.indicator}\n`;
    }
  });
  
    // Add calculation data
    csvContent += "\nCalculation Data\n";
    csvContent += `In ${currencyName} '000,` + location.state.header.map(item => `YE ${item}`).join(",") + "\n";
    selectedData.forEach(row => {
      if (row.calculationLabel) {
        csvContent += `${row.calculationLabel},${row.y5},${row.y6}\n`;
      }
    });
    if (location.state?.calculation?.y5) {
      csvContent += `Total Capital (shareholders equity),${location.state.calculation.y5},${location.state.calculation.y6}\n`;
    }
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    handleClose();
  };
  
  const downloadXML = () => {
    const countryName = location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.xml`;
  
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<FinancialResults>\n';
  
    // Add selected data
    xmlContent += '  <SelectedData>\n';
    selectedData.forEach(row => {
      xmlContent += `    <Result>\n      <Label>${row.label}</Label>\n      <Y1>${row.y1}</Y1>\n      <Y2>${row.y2}</Y2>\n    </Result>\n`;
    });
    xmlContent += '  </SelectedData>\n';
  
    // Add result data
    xmlContent += '  <ResultData>\n';
    selectedData.forEach(row => {
      xmlContent += `    <Result>\n      <Label>${row.resultLabel}</Label>\n      <Y3>${row.y3}</Y3>\n      <Y4>${row.y4}</Y4>\n      <AfreximbankBenchmark>${row.afreximbankBenchmark}</AfreximbankBenchmark>\n      <IndustryBenchmark2022>${row.industryBenchmark2022 || ''}</IndustryBenchmark2022>\n      <IndustryBenchmark2023>${row.industryBenchmark2023 || ''}</IndustryBenchmark2023>\n      <Comments>${row.comments || ''}</Comments>\n    </Result>\n`;
    });
    xmlContent += '  </ResultData>\n';
  
    // Add summary data
    xmlContent += '  <SummaryData>\n';
    Object.entries(summaryData).forEach(([key, value]) => {
      if (key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip') {
        xmlContent += `    <Summary>\n      <Label>${key}</Label>\n      <SummaryText>${value.summary}</SummaryText>\n      <Findings>${value.findings}</Findings>\n      <Indicator>${value.indicator}</Indicator>\n    </Summary>\n`;
      }
    });
    xmlContent += '  </SummaryData>\n';
  
    // Add calculation data
    xmlContent += '  <CalculationData>\n';
    selectedData.forEach(row => {
      if (row.calculationLabel) {
        xmlContent += `    <Calculation>\n      <Label>${row.calculationLabel}</Label>\n      <Y5>${row.y5}</Y5>\n      <Y6>${row.y6}</Y6>\n    </Calculation>\n`;
      }
    });
    if (location.state?.calculation?.y5) {
      xmlContent += `    <Calculation>\n      <Label>TotalCapitalShareholdersEquity</Label>\n      <Y5>${location.state.calculation.y5}</Y5>\n      <Y6>${location.state.calculation.y6}\n    </Calculation>\n`;
    }
    xmlContent += '  </CalculationData>\n';
  
    xmlContent += '</FinancialResults>';
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    saveAs(blob, fileName);
    handleClose();
  };
  
  const downloadDOC = async () => {
    try {
      const countryName = location.state?.selectedCountry?.displayName || 'Country';
      const bankName = location.state?.selectedBank?.bankName || 'Bank';
      const fileName = `${countryName}_${bankName}.docx`;
      const currencyName = location?.state?.currencyName || "Currency";
  
      // Utility function to create tables
      const createTable = (headers, rows) => {
        const headerRow = new DocxTableRow({
          children: headers.map(header => new DocxTableCell({ children: [new Paragraph(header)] })),
        });
  
        const dataRows = rows.map((row, index) =>
          new DocxTableRow({
            children: [
              new DocxTableCell({ children: [new Paragraph((index + 1).toString())] }),
              ...row.map(cell => new DocxTableCell({ children: [new Paragraph(cell || "")] })),
            ],
          })
        );
  
        return new DocxTable({ rows: [headerRow, ...dataRows] });
      };
  
      // Table data preparation
      const headerCells = Array.isArray(location.state?.header)
        ? location.state.header.map(item => `YE ${item}`)
        : [];
      
      const financialTable = createTable(
        [`#`, `In ${currencyName} '000`, ...headerCells],
        selectedData.map(row => [row.label, row.y1 && formatNumberWithCommas(row.y1), row.y2 && formatNumberWithCommas(row.y2)])
      );
  
      const resultTable = createTable(
        [`#`, `In ${currencyName} '000`, ...headerCells, 'Afreximbank benchmark', 'Industry benchmark YE 2022', 'Industry benchmark YE 2023', 'Comments'],
        selectedData.map(row => [
          row.resultLabel,
          row.y3 && formatNumberWithCommas(row.y3),
          row.y4 && formatNumberWithCommas(row.y4),
          row.afreximbankBenchmark,
          '', // Placeholder for Industry benchmark YE 2022
          '', // Placeholder for Industry benchmark YE 2023
          ''  // Placeholder for Comments
        ])
      );
  
      const summaryTable = createTable(
        ["#", "Summary", "Findings", "Indicator"],
        Object.entries(summaryData)
          .filter(([key]) => !["title", "conclusion", "titleTooltip"].includes(key))
          .map(([key, value]) => [value.summary, value.findings, value.indicator])
      );
  
      const calculationTable = createTable(
        [`#`, `In ${currencyName} '000`, ...headerCells],
        selectedData
          .filter(row => row.calculationLabel)
          .map(row => [row.calculationLabel, row.y5 && formatNumberWithCommas(row.y5), row.y6 && formatNumberWithCommas(row.y6)])
          .concat(
            location.state?.calculation?.y5
              ? [["Total Capital (shareholders equity)", formatNumberWithCommas(location.state.calculation.y5), formatNumberWithCommas(location.state.calculation.y6)]]
              : []
          )
      );
  
      // Document construction
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({ children: [new TextRun("Financial Results")] }),
              financialTable,
              new Paragraph({ children: [new TextRun("Result")] }),
              resultTable,
              new Paragraph({ children: [new TextRun("Summary")] }),
              summaryTable,
              new Paragraph({ children: [new TextRun("Calculation")] }),
              calculationTable,
            ],
          },
        ],
      });
  
      // Generate and save the document
      const blob = await Packer.toBlob(doc);
      saveAs(blob, fileName);
      handleClose();
    } catch (error) {
      console.error("Error generating document:", error);
    }
  };
  
  return (
    <>
      <Grid container>
        {/* Page 1 table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              padding: '16px',
              borderRadius: '8px',
            }}
          >
            <Stack>
              <Box display="flex" mt={2} mb={2} gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Country :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedCountry?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedCountry?.displayName}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Bank :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedBank?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedBank?.bankName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Input
              </Typography>
            </Stack>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadClick}
            >
              Download
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={downloadPDF}>Download PDF</MenuItem>
              <MenuItem onClick={downloadCSV}>Download CSV</MenuItem>
              <MenuItem onClick={downloadXML}>Download XML</MenuItem>
              <MenuItem onClick={downloadDOC}>Download DOC</MenuItem>
            </Menu>
            {/* <FormControlLabel
              control={
                <CustomSwitch
                  checked={isResultExpanded}
                  onChange={toggleResult}
                />
              }
              label="External Sources"
              sx={{ marginLeft: '16px' }}
            /> */}
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id= "financial-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '100px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="center" sx={{ width: '100px' }}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ width: '200px', padding: '0px 20px !important' }}
                    >
                      {row.label}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ width: '150px', padding: '0px 20px !important' }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ padding: '20px 12px' }}>
                          {row.y1 && formatNumberWithCommas(row.y1)}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ width: '150px', padding: '0px 20px !important' }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ padding: '20px 12px' }}>
                          {row.y2 && formatNumberWithCommas(row.y2)}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Result table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Result</Typography>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id= "result-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '122px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '245px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                       FI scoring YE {item}
                      </HeadTableCell>
                    ))}
                    <HeadTableCell sx={{ width: '150px' }}>
                      Afreximbank benchmark
                    </HeadTableCell>
                    <HeadTableCell sx={{ width: '150px' }}>
                      Industry benchmark YE 2022
                    </HeadTableCell>
                    <HeadTableCell sx={{ width: '150px' }}>
                      Industry benchmark YE 2023
                    </HeadTableCell>
                    <HeadTableCell sx={{ width: '150px' }}>
                      Comments
                    </HeadTableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="center" sx={{ width: '100px' }}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {index === 0 ? (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                          <Stack>
                            ({location.state?.selectedCountry?.displayName}{' '}
                            {row.resultLabel} Limit:- {row?.limit}%)
                          </Stack>
                        </>
                      ) : (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                        </>
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color:
      row?.afreximbankBenchmark && typeof row.afreximbankBenchmark === 'string' &&
      parseInt(row?.y3.split('%')[0]) >= parseInt(row.afreximbankBenchmark.replace('%', '').replace('>=', '').replace('<=', ''))
        ? 'red' // Change to red if above or equal to benchmark
        : 'inherit',
  }}
                    >
                      {isResultExpanded
                        ? row?.y7 && formatNumberWithCommas(row.y7)
                        : row?.y3 && formatNumberWithCommas(row.y3)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color:
      row?.afreximbankBenchmark && typeof row.afreximbankBenchmark === 'string' &&
      parseInt(row?.y4.split('%')[0]) >= parseInt(row.afreximbankBenchmark.replace('%', '').replace('>=', '').replace('<=', ''))
        ? 'red' // Change to red if above or equal to benchmark
        : 'inherit',
  }}
                    >
                      {isResultExpanded
                        ? row?.y8 && formatNumberWithCommas(row.y8)
                        : row?.y4 && formatNumberWithCommas(row.y4)}
                    </StyledTableCell>
                    <StyledTableCell>{row.afreximbankBenchmark}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box
          mb={2}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography variant="Body_semibold_32">Summary</Typography>
          <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          
          <Typography variant="Body_semibold_20">
          {location.state?.summaryData?.title} (Afreximbank)
          <CustomTooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="body2" component="div">
              <strong>Rating 1:</strong> Strong performance, effective risk management, and stable operations.<br/>
              <strong>Rating 2:</strong> Satisfactory performance with minor weaknesses that are being addressed.<br/>
              <strong>Rating 3:</strong> Fair performance with some concerns that need attention.<br/> 
              <strong>Rating 4:</strong> Poor performance with significant weaknesses that require immediate corrective action.<br/>
              <strong>Rating 5:</strong> Very weak performance with severe deficiencies that pose a high risk to the bank's stability.
            </Typography>
          </Box>
        }
        sx={{ color: '#000000' }}
        arrow
      >
        <InfoIcon
          style={{
            marginLeft: '8px',
            fontSize: '1.2rem',
            cursor: 'pointer',
          }}
        />
      </CustomTooltip>
            </Typography>
      </Box>
      <Typography 
      variant="Body_normal_16"
      sx={{ wordBreak: 'break-word' }}
    >
      <strong>Conclusion:</strong>{location.state?.summaryData?.conclusion}
    </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          
          <Typography variant="Body_semibold_20">
          {location.state?.summaryData?.title}
          <CustomTooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="body2" component="div">
              <strong>Rating 1:</strong> Strong performance, effective risk management, and stable operations.<br/>
              <strong>Rating 2:</strong> Satisfactory performance with minor weaknesses that are being addressed.<br/>
              <strong>Rating 3:</strong> Fair performance with some concerns that need attention.<br/> 
              <strong>Rating 4:</strong> Poor performance with significant weaknesses that require immediate corrective action.<br/>
              <strong>Rating 5:</strong> Very weak performance with severe deficiencies that pose a high risk to the bank's stability.
            </Typography>
          </Box>
        }
        sx={{ color: '#000000' }}
        arrow
      >
        <InfoIcon
          style={{
            marginLeft: '8px',
            fontSize: '1.2rem',
            cursor: 'pointer',
          }}
        />
      </CustomTooltip>
            </Typography>
      </Box>
  
    <Typography 
      variant="Body_normal_16"
      sx={{ wordBreak: 'break-word' }}
    >
      <strong>Conclusion:</strong>{location.state?.summaryData?.conclusion}
    </Typography>
  </Box>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '12px', overflow: 'hidden' }}
      >
        <Table
          sx={{
            minWidth: 650,
            width: '100%',
          }}
          aria-label="financial table"
          id= "summary-table"
        >
          <TableHead
            sx={{
              background: '#243842',
            }}
          >
            <TableRow>
              <HeadTableCell align="center" sx={{ width: '130px' }}>
                #
              </HeadTableCell>
              <HeadTableCell sx={{ width: '265px', textAlign: 'center' }}>
                Summary
              </HeadTableCell>
              <HeadTableCell sx={{ width: '200px', textAlign: 'center' }}>
                Findings
              </HeadTableCell>
              <HeadTableCell sx={{ width: '200px', textAlign: 'center' }}>
                Indicator
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ background: '#001823' }}>
              {summaryData && Object.entries(summaryData)
                .filter(([key]) => key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip')
                .map(([key, value], index) => ( 
                <TableRow key={key}>
              <StyledTableCell align="center">
            {index + 1} 
          </StyledTableCell>
        <StyledTableCell component="th" scope="row">
    {value.summary?.split('|').map((part, idx) => (
    <React.Fragment key={idx}>
      {idx === 0 ? (
        <span>
          {part.trim()}
          {value.toolkit && value.toolkit.length > 0 && (
            <CustomTooltip
              title={
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {value.toolkit.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              }
              sx={{ color: '#000000' }}
              arrow
            >
              <InfoIcon sx={{ fontSize: 16, ml: 1, verticalAlign: 'middle' }} />
            </CustomTooltip>
          )}
        </span>
      ) : (
        <>
          <br />
          {part.trim()}
        </>
      )}
    </React.Fragment>
  ))}
</StyledTableCell>
        <StyledTableCell>{value.findings}</StyledTableCell>
        <StyledTableCell style={{textAlign: 'center'}}>
          {value.indicator === 'green' ? (
            <span style={{ color: 'green', fontSize: '2.5rem' }}>●</span>
          ) : value.indicator === 'amber' ? (
            <span style={{ color: '#FFBF00', fontSize: '2.5rem' }}>●</span>
          ) : (
            value.indicator
          )}
        </StyledTableCell>
      </TableRow>
    ))}
</TableBody>
        </Table>
      </TableContainer>
    </Grid>

        {/* Calculation table with expand/collapse functionality */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Calculation</Typography>
            <Button
              variant="text"
              onClick={toggleCalculation}
              startIcon={
                isCalculationExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
            >
              {isCalculationExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id= "calculation-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '120px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '250px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              {isCalculationExpanded && ( // Conditional rendering of TableBody
                <TableBody
                  sx={{
                    background: '#001823',
                  }}
                >
                  {selectedData.map((row, index) => (
                    <>
                      {row.calculationLabel && (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0,
                            },
                          }}
                        >
                          <StyledTableCell
                            align="center"
                            sx={{ width: '100px' }}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{
                              width: '200px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row.calculationLabel}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y5 && formatNumberWithCommas(row?.y5)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y6 && formatNumberWithCommas(row?.y6)}
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                  {location.state?.calculation?.y5 && (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell align="center" sx={{ width: '100px' }}>
                        {selectedData.length + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ width: '200px', padding: '0px 20px !important' }}
                      >
                        Total Capital (shareholders equity)
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y5
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y6
                        )}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12} my={4} mb={20} sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={3}
          >
            <AnimateButton>
              <Button
                variant="outlined"
                size="large"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/financial-institution')}
              >
                Prev
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={() =>
                  navigate('/financial-loan-breakdown', {
                    state: location.state,
                  })
                }
              >
                Next
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default FiResult;
