import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../common/Logo";
// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  minHeight: "100vh",
  overflow: "hidden",
  position: "relative",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  // overflow: "auto",
  paddingTop: 0,
  [theme.breakpoints.up("lg")]: {
    paddingTop: 80,
  },
}));

// ----------------------------------------------------------------------

export function ExternalLoginLayout() {
  return (
    <>
      <RootStyle>
        
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
