import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography } from '@mui/material';
import MainCard from '../../components/common/MainCard';

const chartOptionsData = {
  chart: {
    type: 'line',
    height: 450,
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
  },
  stroke: {
    width: 2,
    colors: ['#FF4343'], // Orange for the high spike series
  },
  markers: {
    size: 6,
    colors: ['#FF4343'], // Orange for the high spike series
  },
  xaxis: {
    categories: Array.from({ length: 31 }, (_, i) => i + 1), // Numeric categories from 1 to 31
    labels: {
      style: {
        colors: '#ffffff',
      },
    },
  },
  yaxis: {
    min: 0,
    max: 6000,
    tickAmount: 6, // This will create 7 ticks (0, 1000, 2000, 3000, 4000, 5000, 6000)
    labels: {
      style: {
        colors: '#ffffff',
      },
    },
    title: {
      text: '',
    },
  },
  tooltip: {
    theme: 'dark',
  },
  grid: {
    borderColor: '#555555',
  },
  legend: {
    show: true,
    position: 'right',
    horizontalAlign: 'center',
    offsetY: 0,
    labels: {
      colors: '#ffffff',
    },
    markers: {
      width: 12,
      height: 12,
      radius: 12,
    },
  },
};

const seriesData = [
  {
    name: 'Amount Credited',
    data: [
      0, 500, 0, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 0
    ], // Specified data points with increasing line from 3 to 12
    color: '#FF4343', // Orange color
  },
];

const TransactionAmountSpikeChart = () => {
  return (
    <>
      <MainCard
        id="chart"
        sx={{
          padding: '10px 16px 0px !important',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="Body_semibold_20" color="orange.main">
            Funds credited to this account
          </Typography>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: 'orange.main',
                mr: 1,
              }}
            />
            <Typography variant="body2" color="orange.main">
              Amount Credited
            </Typography>
          </Box>
        </Box>
        <ReactApexChart
          options={chartOptionsData}
          series={seriesData}
          type="line"
          height={450}
        />
      </MainCard>
    </>
  );
};

export default TransactionAmountSpikeChart;