import styled from "@emotion/styled";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Autocomplete,
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import { CustomPaper } from '../../components/common/style';
import {
  ButtonWrapper,
  CommonWrapper,
  LabelDescription,
  PageTitle,
  PageWrapper,
  ProductCategoryInfo,
  ProductCategoryInfoWrapper,
  TitleWrapper,
} from './PermissionStyle';
// import BANKLIST from "../../_mockData_/BankList";
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import BasicStatement from '../../assets/Basic-statement.svg';
import Percentage from '../../assets/Percentage.svg';
import RealtimeBalance from '../../assets/Realtime-balance.svg';
import Loader from '../../components/common/Loader';
import PolicyAndTermsText from '../../components/common/PolicyAndTermsText';
import GetAxiosInstance from '../../components/common/api';
import { getUserData } from '../../components/common/common';
import RoundedCheckBoxStyled from '../../components/common/formStyle/RoundedCheckBoxStyled';
import { ACCOUNT_TYPES } from '../../util/accountTypes';
import banksList from '../../util/bankList';
import { BpRadio } from '../bank-selection/BankSelectionStyle';
import ProductDetails from './ProductDetails';
import CustomDialog from "../../components/common/AccessBankPopup";

const ExpandMore1 = styled((props) => {
  const { expand1, ...other } = props;
  return <IconButton {...other} expand={expand1?.toString()} />;
})(({ theme, expand }) => ({
  transform: expand === 'true' ? 'rotate(0deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore02 = styled((props) => {
  const { expand02, ...other } = props;
  return <IconButton {...other} expand={expand02?.toString()} />;
})(({ theme, expand }) => ({
  transform: expand === 'true' ? 'rotate(0deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const businessPartnerName = [
  { title: 'Nibss Plc', id: 1 },
  { title: 'One Credit', id: 2 },
  { title: 'UK embassy', id: 3 },
];

const productName = [
  { title: 'Statement', id: 1 },
  { title: 'Transactions', id: 2 },
  { title: 'Risk Indicators', id: 3 },
];
const monthRangeList = [
  { title: '1 Month', value: 1 },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];

const accountTypeList = ACCOUNT_TYPES;
const defaultBanks =
  process.env.REACT_APP_BUSINESS_DEFAULT_BANK?.split(',') || [];
const businessPartnerId = process.env.REACT_APP_BUSINESS_PARTNER_ID;
const numberOfMonths = process.env.REACT_APP_NUMBER_OF_MONTHS || 6;

const AccountInformation = () => {
  const theme = useTheme();
  const [monthRange, setMonthRange] = useState(monthRangeList);
  const { externalAppConsentId } = useParams();
  const [userData, setUserData] = useState();
  const { state } = useLocation();
  useEffect(() => {
    let userData = getUserData();
    setUserData(userData);
    sessionStorage.setItem('eDoc:ExternalAppConsentId', externalAppConsentId);
  }, []);
  const [accountDetails, setAccountDetails] = useState('');
  const [bankList, setBankList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [startDate, setStateDate] = useState();
  const [toDate, setToDate] = useState();
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded02, setExpanded02] = useState(true);
  const [loader, setLoader] = useState(false);
  const [selectedBankLogo, setSelectedBankLogo] = useState('');
  const [partnerList, setPartnerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isGuests, setIsGuests] = useState(true);
  const [partnerId, setPartnerId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [expanded3, setExpanded3] = useState(true);
  const [dateArray, setDateArray] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [pofSelectedMonth, setPofSelectedMonth] = useState([]);
  const [kycSelectedMonth, setKycSelectedMonth] = useState([]);
  const [affordabilitySelectedMonth, setAffordabilitySelectedMonth] = useState(
    []
  );
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [accountNumberError, setAccountNumberError] = useState('');
  const [bankSelectError, setBankSelectError] = useState('');
  const [monthCount, setMonthCount] = useState([]);
  const [pofMonthCount, setPofMonthCount] = useState([]);
  const [kycMonthCount, setKycMonthCount] = useState([]);
  const [affordabilityMonthCount, setAffordabilityMonthCount] = useState([]);
  const [companyNumber, setCompanyNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [newConsent, setNewConsent] = useState();
  const [selectBusinessPartnerError, setSelectBusinessPartnerError] =
    useState('');
  let [statement, setStatement] = useState([]);
  let [pof, setPof] = useState([]);
  let [kyc, setKyc] = useState([]);
  let [balance, setBalance] = useState([]);
  let [affordability, setAffordability] = useState([]);
  const [selectPartnerBox, setSelectPartnerBox] = useState(true);
  const [productLoader, setProductLoader] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');

  let navigate = useNavigate();

  const fetchAccountName = async (accountNumber, bankName) => {
    try {
      const response = await GetAxiosInstance(
        'orchestrator/account-name',
        'POST',
        { accountNumber, bankName },
        {},
        null
      );
      return response.data.data.name;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

const handleAccountNumberChange = async (e) => {
  const number = e.target.value;
  setAccountDetails((prevDetails) => ({
    ...prevDetails,
    number,
  }));

  if (number && accountDetails.bankName) {
    const accountName = await fetchAccountName(number, accountDetails.bankName);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      accountName,
    }));
  }
};

const handleBankChange = async (e) => {
  const bankName = e.target.getAttribute('name');
  handleChange(e);
  setAccountDetails((prevDetails) => ({
    ...prevDetails,
    bankName,
  }));

  if (accountDetails.number && bankName) {
    const accountName = await fetchAccountName(accountDetails.number, bankName);
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      accountName,
    }));
  }
};
  useEffect(async () => {
    let localStorageData = localStorage.getItem('userData');
    localStorageData = JSON.parse(localStorageData);
    if (localStorageData && localStorageData?.userType === 'nonGuest') {
      setIsGuests(false);
    }
    GetAxiosInstance('orchestrator/businessPartners', 'get').then((res) => {
      if (res?.status === 200) {
        setPartnerList(res?.data.length ? res?.data : res?.data?.data);
      }
    });
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    var d = new Date();
    d.setDate(1); //REM: To prevent month skipping.
    let dateArray = [];
    for (var i = 0; i < 12; i++) {
      d.setMonth(i ? d.getMonth() - 1 : d.getMonth());
      let obj = {
        label: `${monthNames[d.getMonth()]} - ${d.getFullYear()}`,
        value: `${d.getFullYear()}-${moment(
          monthNames[d.getMonth()],
          'MMMM'
        ).format('MM')}`,
      };
      dateArray.push(obj);
    }
    setDateArray(dateArray);
    setProductLoader(true);
    GetAxiosInstance('orchestrator/entitlement/products', 'get').then((res) => {
      if (res?.status === 200) {
        setProductLoader(false);
        setProductList(res.data?.data);
      } else {
        setProductLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
    // localhost
    if (!JSON.parse(localStorage.getItem('userData')).isGuestUser) {
      localStorage.removeItem('deepLinkId');
    }
    setBankList(await banksList());
  }, []);
  useEffect(() => {
    if (localStorage.getItem('deepLinkId')) {
      const selectedPartner =
        partnerList.length &&
        partnerList.find(
          (item) => item?.uniqueId === localStorage.getItem('deepLinkId')
        );

      if (selectedPartner?.id === parseInt(businessPartnerId)) {
        // Find the bank in bankList that matches one of the default banks
        const matchingBank = bankList.filter((bank) =>
          defaultBanks.includes(bank.bankName)
        );
        // If a matching bank is found, set it as the bank state
        if (matchingBank.length) {
          setBankList(matchingBank);
        }
        setMonthRange(monthRangeList.slice(0, 3));
      }
    }
  }, [partnerList]);
  useEffect(() => {
    if (localStorage.getItem('deepLinkId')) {
      const selectedPartner =
        partnerList.length &&
        partnerList.find(
          (item) => item?.uniqueId === localStorage.getItem('deepLinkId')
        );
      setPartnerId(selectedPartner?.id);
    }
  }, [partnerList]);
  useEffect(() => {
    bankList.find((item) => {
      if (item.bankName === accountDetails?.bankName) {
        setSelectedBankLogo(item.icon);
      }
    });
  }, [accountDetails]);
  const handleChange = (e) => {
    if (e.target.getAttribute('name') === 'access-mock') {
      setOpenPopup(true);
    }
  };
  const handleClose = () => {
    // Close the popup
    setOpenPopup(false);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const handleExpandClick02 = () => {
    setExpanded02(!expanded02);
  };

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };

  const handleExpandClickSelectPartner = () => {
    setSelectPartnerBox(!selectPartnerBox);
  };

  useEffect(() => {
    statement = [...statement];
    statement.forEach((v) => {
      v.months = selectedMonth;
    });
    setStatement(statement);
  }, [selectedMonth]);
  useEffect(() => {
    pof = [...pof];
    pof.forEach((v) => {
      v.months = pofSelectedMonth;
    });
    setPof(pof);
  }, [pofSelectedMonth]);
  useEffect(() => {
    kyc = [...kyc];
    kyc.forEach((v) => {
      v.months = kycSelectedMonth;
    });
    setKyc(kyc);
  }, [kycSelectedMonth]);
  useEffect(() => {
    affordability = [...affordability];
    affordability.forEach((v) => {
      v.months = affordabilitySelectedMonth;
    });
    setAffordability(affordability);
  }, [affordabilitySelectedMonth]);
  const handleSelectProduct = (e) => {
    statement = [...statement];
    balance = [...balance];
    affordability = [...affordability];
    pof = [...pof];
    kyc = [...kyc];
    let findProduct = productList.find((item) => {
      return item.id === parseInt(e.target.name);
    });
    let obj = {
      id: findProduct.id,
      code: findProduct.code,
      group: findProduct.group,
      price: findProduct.price,
    };
    if (findProduct.group === 'statement') {
      setSelectedMonth([]);
      if (e.target.checked) {
        obj.months = [];
        statement = [obj];
      } else {
        const index = statement.findIndex(
          (item) => item?.code === 'statement_plus'
        );
        statement.splice(index, 1);
      }
    } else if (findProduct.group === 'affordability') {
      if (e.target.checked) {
        // obj["startDate"] = moment().subtract(monthCount, "months")._d;
        // obj["endDate"] = moment()._d;
        affordability = [obj];
      } else {
        affordability = [];
      }
    } else if (findProduct.group === 'kyc') {
      if (e.target.checked) {
        kyc.push(obj);
      } else {
        setCompanyNumber();
        const index = kyc.findIndex((item) => item?.code === 'kyc');
        kyc.splice(index, 1);
      }
    } else if (findProduct.group === 'pof') {
      if (e.target.checked) {
        pof.push(obj);
      } else {
        setCompanyNumber();
        const index = pof.findIndex((item) => item?.code === 'pof');
        pof.splice(index, 1);
      }
    } else {
      if (e.target.checked) {
        balance = [obj];
      } else {
        balance = [];
      }
    }
    setKyc(kyc);
    setStatement(statement);
    setPof(pof);
    setBalance(balance);
    setAffordability(affordability);
  };
  const handleAllow = (e) => {
    let accountNumberError = '';
    let selectBusinessPartnerError = '';
    let accountTypeError = '';
    let bankSelectError = '';
    if (!accountDetails?.number) {
      accountNumberError = 'Please Enter Account Number';
    } else {
      accountNumberError = '';
    }
    if (!accountType) {
      accountTypeError = 'Please select Account Type';
    } else {
      accountTypeError = '';
    }
    if (!partnerId) {
      selectBusinessPartnerError = 'Please Select Business Partner  ';
    } else {
      selectBusinessPartnerError = '';
    }
    if (!accountDetails?.bankName) {
      bankSelectError = 'Please select Bank';
    } else {
      bankSelectError = '';
    }

    setAccountTypeError(accountTypeError);
    setAccountNumberError(accountNumberError);
    setSelectBusinessPartnerError(selectBusinessPartnerError);
    setBankSelectError(bankSelectError);
    if (accountDetails?.number && accountDetails?.bankName && accountType) {
      let payload = {
        consentType: 'CREATE',
        bankName: accountDetails?.bankName,
        consent: {
          data: {
            accountNumber: accountDetails?.number,
            products: [
              ...statement,
              ...balance,
              ...affordability,
              ...pof,
              ...kyc,
            ],
          },
        },
        partnerId: partnerId,
        companyNumber: companyNumber,
        accountType: accountType,
      };
      if (partnerId) {
        payload['partnerId'] = partnerId;
        setIsOpen(true);
        setNewConsent(payload);
      } else {
        setLoader(true);
        GetAxiosInstance(
          externalAppConsentId
            ? `orchestrator/consent/${externalAppConsentId}/attach-account`
            : 'orchestrator/initialize-consent',
          'post',
          payload
        ).then((res) => {
          if (res?.data?.codes === 200) {
            let postObj = {
              journey: 'CONSENT',
              eventName: 'CONSENT.INITIATED',
              status: 'SUCCESS',
              uniqueIdentifier: res?.data?.data?.consentId,
              subIdentifier: accountDetails?.bankName,
              data: {
                req: payload,
                res: res?.data?.data,
              },
              source: 'CONNECT',
            };

            GetAxiosInstance(`log/eventLog`, 'post', postObj);
            if (res?.data?.data?.verificationType === 1) {
              setLoader(false);
              navigate('/pending-approval', {
                state: {
                  ...res.data?.data,
                  bankName: accountDetails?.bankName,
                },
              });
            } else if (
              res?.data?.data?.verificationType === 2 ||
              res?.data?.data?.verificationType === 3
            ) {
              setLoader(false);

              navigate('/verify-otp', {
                state: {
                  ...res.data?.data,
                  bankName: accountDetails?.bankName,
                },
              });
            } else if (
              res?.data?.data?.verificationType === 0 ||
              res?.data?.data?.verificationType === 'none'
            ) {
              // setLoader(false);
              const payload = {
                consentId: res?.data?.data?.consentId,
              };
              GetAxiosInstance(
                'orchestrator/get-transactions',
                'post',
                payload,
                userData.accessToken
              ).then((res) => {
                if (res?.data?.codes === 200) {
                  setLoader(false);
                  if (externalAppConsentId) {
                    navigate(
                      `/consent-request/${externalAppConsentId}/consent-success`,
                      {
                        state: {
                          ...res.data?.data,
                        },
                      }
                    );
                  } else
                    navigate('/dashboard', {
                      state: {
                        ...res.data?.data,
                        bankName:
                          accountDetails?.bankName === 'wema'
                            ? process.env.REACT_APP_WEMA_KEY
                            : accountDetails?.bankName,
                      },
                    });
                } else {
                  if (externalAppConsentId) {
                    navigate(
                      `/consent-request/${externalAppConsentId}/consent-error`,
                      {
                        state: {
                          ...res.data?.data,
                        },
                      }
                    );
                  } else {
                    setLoader(false);
                    toast.error(res?.response?.data?.message || res.message);
                  }
                }
              });
            }
          } else {
            const postObj = {
              journey: 'CONSENT',
              eventName: 'CONSENT.INITIATED',
              status: 'ERROR',
              uniqueIdentifier: res?.data?.data?.consentId,
              subIdentifier: accountDetails?.bankName,
              data: {
                req: payload,
                res: res.response.data.message,
              },
              source: 'CONNECT',
            };
            GetAxiosInstance(`log/eventLog`, 'post', postObj);
            setLoader(false);
            toast.error(res?.response?.data?.message || res.message);
          }
        });
      }
    }
    // setAccountNumberError("");
    setSelectBusinessPartnerError('');
    // setBankSelectError("");
  };
  const handleIsSelected = (item) => {
    let allSelectedPermission = [
      ...statement,
      ...balance,
      ...affordability,
      ...pof,
      ...kyc,
    ];
    if (allSelectedPermission.length) {
      return (
        allSelectedPermission.findIndex((v) => {
          return item.id === v.id;
        }) > -1
      );
    }
  };
  const handleSelectedMonth = (event, newValue) => {
    const {
      target: { value },
    } = event;
    let selectedMonths =
      dateArray.length && dateArray.filter((month, idx) => idx < value);
    selectedMonths = selectedMonths.map((item) => item.value);
    setMonthCount(value);
    setSelectedMonth(selectedMonths);
  };

  const handleAccountTypeSelection = (event, newVal) => {
    const {
      target: { value },
    } = event;

    setAccountType(value);
  };

  const handlePofSelectedMonth = (event, newValue) => {
    const {
      target: { value },
    } = event;
    let selectedMonths =
      dateArray.length && dateArray.filter((month, idx) => idx < value);
    selectedMonths = selectedMonths.map((item) => item.value);
    setPofMonthCount(value);
    setPofSelectedMonth(selectedMonths);
  };
  const handleKycSelectedMonth = (event, newValue) => {
    const {
      target: { value },
    } = event;
    let selectedMonths =
      dateArray.length && dateArray.filter((month, idx) => idx < value);
    selectedMonths = selectedMonths.map((item) => item.value);
    setKycMonthCount(value);
    setKycSelectedMonth(selectedMonths);
  };
  const handleAffordabilitySelectedMonth = (event, newValue) => {
    const {
      target: { value },
    } = event;
    let selectedMonths =
      dateArray.length && dateArray.filter((month, idx) => idx < value);
    selectedMonths = selectedMonths.map((item) => item.value);
    setAffordabilityMonthCount(value);
    setAffordabilitySelectedMonth(selectedMonths);
  };
  const handleCheck = (e) => {
    setAccountType(e.target.value);
  };
  return (
    <>
      <PageWrapper>
        <TitleWrapper>
          <PageTitle>Permission for account information sharing</PageTitle>
        </TitleWrapper>

        <CommonWrapper>
          <Box sx={{ width: '100%' }}>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="Body_medium_20" color="orange.main">
                {/* Select your Bank */}
              </Typography>
              <ExpandMore1
                expand={expanded1}
                onClick={handleExpandClick1}
                aria-expanded={expanded1}
                aria-label="show more"
              >
                {expanded1 ? (
                  <RemoveIcon sx={{ fill: theme.palette.orange.main }} />
                ) : (
                  <AddIcon sx={{ fill: theme.palette.orange.main }} />
                )}
              </ExpandMore1>
            </CardContent>

            <Divider sx={{ borderColor: theme.palette.primary.lighter }} />

            <Collapse in={expanded1}>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {/* <Typography variant="Body_light_16" color="grey.darker">
                  To select your bank feed we need to know which bank to connect
                  to.
                </Typography> */}

                <Grid container gap={4} alignItems="flex-start">
                  <Grid item md={7} sm={4}>
                    <Typography
                      className="account-information-label"
                      variant="Body_medium_16"
                      color="orange.main"
                    >
                      Account Number
                    </Typography>
                    <TextField
                      id="filled-hidden-label-normal"
                      hiddenLabel
                      inputProps={{ 'aria-label': 'Without label' }}
                      fullWidth
                      variant="filled"
                      placeholder="Enter your account number"
                      autoComplete="off"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={accountDetails?.number || ''}
                      helperText={accountNumberError && accountNumberError}
                      onChange={handleAccountNumberChange}
                    />

                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                      mt={2}
                    >
                      <Typography variant="Body_medium_14" color="grey.light">
                      {accountDetails?.accountName}
                      </Typography>

                      <Typography variant="Body_medium_14" color="orange.main">
                        Verified account details
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item md={4} sm={4}>
                    <Typography
                      className="account-information-label"
                      variant="Body_medium_16"
                      color="orange.main"
                    >
                      Bank
                    </Typography>
                    <Autocomplete
                      id="select-product"
                      options={bankList}
                      disableClearable
                      getOptionDisabled={(option) => !option?.enabled}
                      getOptionLabel={(option) => option.displayName}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          key={option.bankName}
                          name={option.bankName}
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            name={option.bankName}
                            width="48"
                            style={{ borderRadius: '50%' }}
                            src={option.icon}
                            alt=""
                          />
                          {option.displayName}
                        </Box>
                      )}
                      PaperComponent={CustomPaper}
                      onChange={handleBankChange}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          hiddenLabel
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {selectedBankLogo && (
                                  <Box
                                    component="img"
                                    src={selectedBankLogo}
                                    alt=""
                                    sx={{
                                      width: '48px',
                                      height: '48px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      )}
                    />
                    <Box
                      component={'div'}
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '.75rem',
                        lineHeight: '1.66',
                        textAlign: 'left',
                        margin: '3px 14px 0 14px',
                        color: 'red',
                      }}
                    >
                      {bankSelectError && bankSelectError}
                    </Box>
                    <CustomDialog open={openPopup} handleClose={handleClose} />
                  </Grid>
                  <Grid item md={4} sm={4}>
                    <Typography
                      className="account-information-label"
                      variant="Body_medium_16"
                      color="orange.main"
                    >
                      Account Type
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={accountType}
                        onClick={handleCheck}
                      >
                        <Stack direction={'row'} width={'max-content'}>
                          <FormControlLabel
                            value="Personal"
                            control={<BpRadio />}
                            labelPlacement="end"
                            label="Personal Account"
                          />
                          <FormControlLabel
                            value="Business"
                            control={<BpRadio />}
                            labelPlacement="end"
                            label="Business Account"
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                    <Box
                      component="div"
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '.75rem',
                        lineHeight: '1.66',
                        textAlign: 'left',
                        margin: '3px 14px 0 14px',
                        color: 'red',
                      }}
                    >
                      {accountTypeError && accountTypeError}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Box>
          {productLoader ? (
            <Loader />
          ) : (
            <Box sx={{ width: '100%' }}>
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="Body_medium_20" color="orange.main">
                  Products
                </Typography>
                <ExpandMore02
                  expand={expanded02}
                  onClick={handleExpandClick02}
                  aria-expanded={expanded02}
                  aria-label="show more"
                >
                  {expanded02 ? (
                    <RemoveIcon sx={{ fill: theme.palette.orange.main }} />
                  ) : (
                    <AddIcon sx={{ fill: theme.palette.orange.main }} />
                  )}
                </ExpandMore02>
              </CardContent>

              <Divider sx={{ borderColor: theme.palette.primary.lighter }} />

              <Collapse in={expanded02}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {productList.map((item) => {
                    return (
                      <FormControl key={item.id}>
                        <Box>
                          <ProductCategoryInfoWrapper>
                            {item.group === 'statement' && (
                              <Box
                                component="img"
                                src={BasicStatement}
                                alt="BasicStatement"
                              />
                            )}{' '}
                            {item.group === 'balance' && (
                              <Box
                                component="img"
                                src={RealtimeBalance}
                                alt="RealtimeBalance"
                              />
                            )}
                            {item.group === 'affordability' && (
                              <Box
                                component="img"
                                src={Percentage}
                                alt="Percentage"
                              />
                            )}
                            {item.group === 'kyc' && (
                              <Box
                                component="img"
                                src={Percentage}
                                alt="Percentage"
                              />
                            )}
                            {item.group === 'pof' && (
                              <Box
                                component="img"
                                src={RealtimeBalance}
                                alt="RealtimeBalance"
                              />
                            )}
                            <ProductCategoryInfo>
                              <FormControlLabel
                                checked={handleIsSelected(item) || false}
                                name={item.id.toString()}
                                control={<RoundedCheckBoxStyled />}
                                label={`${item.name}`}
                                labelPlacement="start"
                                sx={{
                                  ml: 0,
                                  alignItems: 'start',
                                }}
                                onChange={handleSelectProduct}
                              />
                              <LabelDescription color="grey.darker" ml={1.5}>
                                {item.description}
                              </LabelDescription>
                            </ProductCategoryInfo>
                          </ProductCategoryInfoWrapper>
                          {item.group === 'statement' && (
                            <>
                              {statement.findIndex((v) => v.id === item.id) >
                                -1 && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      name={item.id?.toString()}
                                      hiddenLabel
                                      // inputProps={{
                                      //   'aria-label': 'Without label',
                                      // }}
                                      value={monthCount}
                                      onChange={handleSelectedMonth}
                                      sx={{ m: 1, minWidth: 200 }}
                                    >
                                      <Select
                                        // multiple
                                        placeholder="Select..."
                                        variant="filled"
                                        name={item.id?.toString()}
                                        hiddenLabel
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        value={monthCount}
                                        onChange={handleSelectedMonth}
                                      >
                                        {monthRange.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.title}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                          {item.group === 'pof' && (
                            <>
                              {pof.findIndex((v) => v.id === item.id) > -1 && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      name={item.id?.toString()}
                                      hiddenLabel
                                      // inputProps={{
                                      //   'aria-label': 'Without label',
                                      // }}
                                      value={pofMonthCount}
                                      onChange={handlePofSelectedMonth}
                                    >
                                      <Select
                                        // multiple
                                        placeholder="Select..."
                                        variant="filled"
                                        name={item.id?.toString()}
                                        hiddenLabel
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        value={pofMonthCount}
                                        onChange={handlePofSelectedMonth}
                                      >
                                        {monthRange.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.title}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                          {item.group === 'affordability' && (
                            <>
                              {affordability.findIndex(
                                (v) => v.id === item.id
                              ) > -1 && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      name={item.id?.toString()}
                                      hiddenLabel
                                      // inputProps={{
                                      //   'aria-label': 'Without label',
                                      // }}
                                      value={affordabilityMonthCount}
                                      onChange={
                                        handleAffordabilitySelectedMonth
                                      }
                                      sx={{ m: 1, minWidth: 200 }}
                                    >
                                      <Select
                                        // multiple
                                        placeholder="Select..."
                                        variant="filled"
                                        name={item.id?.toString()}
                                        hiddenLabel
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        value={affordabilityMonthCount}
                                        onChange={
                                          handleAffordabilitySelectedMonth
                                        }
                                      >
                                        {monthRange.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.title}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                          {item.group === 'kyc' && (
                            <Box
                              component="div"
                              className="d-flex justify-content-center"
                            >
                              {kyc.find((item) => item?.code === 'kyc') && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      sx={{ m: 1, minWidth: 200 }}
                                    >
                                      <Typography>
                                        CAC Registration Number
                                      </Typography>
                                      <TextField
                                        variant="filled"
                                        type="text"
                                        onChange={(e) =>
                                          setCompanyNumber(e.target.value)
                                        }
                                        placeholder="Enter CAC Registration Number"
                                        id="filled-hidden-label-normal"
                                        fullWidth
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        hiddenLabel
                                        //  {...register("email")}
                                        //  helperText={errors.email && errors.email.message}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                              {kyc.findIndex((v) => v.id === item.id) > -1 && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      name={item.id?.toString()}
                                      hiddenLabel
                                      // inputProps={{
                                      //   'aria-label': 'Without label',
                                      // }}
                                      value={kycMonthCount}
                                      onChange={handleKycSelectedMonth}
                                      sx={{ m: 1, minWidth: 200 }}
                                    >
                                      <Select
                                        // multiple
                                        placeholder="Select..."
                                        variant="filled"
                                        name={item.id?.toString()}
                                        hiddenLabel
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        value={kycMonthCount}
                                        onChange={handleKycSelectedMonth}
                                      >
                                        {monthRange.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.title}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                            </Box>
                          )}
                          {item.group === 'affordability' && (
                            <>
                              {statement.find(
                                (item) => item?.code === 'affordability'
                              ) && (
                                <Grid
                                  container
                                  gap={4}
                                  alignItems="flex-start"
                                  mt={2}
                                >
                                  <Grid item md={7} sm={4}>
                                    <FormControl
                                      variant="filled"
                                      sx={{ m: 1, minWidth: 200 }}
                                    >
                                      <Typography>
                                        CAC Registration Number
                                      </Typography>
                                      <TextField
                                        variant="filled"
                                        type="text"
                                        onChange={(e) =>
                                          setCompanyNumber(e.target.value)
                                        }
                                        placeholder="Enter CAC Registration Number"
                                        id="filled-hidden-label-normal"
                                        fullWidth
                                        // inputProps={{
                                        //   'aria-label': 'Without label',
                                        // }}
                                        hiddenLabel
                                        //  {...register("email")}
                                        //  helperText={errors.email && errors.email.message}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </Box>
                        {/* </RadioGroup> */}
                      </FormControl>
                    );
                  })}
                </CardContent>
              </Collapse>
            </Box>
          )}
          <Divider sx={{ borderColor: theme.palette.primary.lighter }} />
          <Collapse in={expanded3}>
            <CardContent>
              <Typography variant="Body_light_16" color="common.white">
                This person/organisation will have access to your account
                information (balance, statements, transactions). You can cancel
                their access anytime.
              </Typography>

              <Grid container gap={4} mt={3}>
                <Grid item md={4} xs={12}>
                  <Typography variant="Body_medium_16" color="orange.main">
                    Business Partner Name
                  </Typography>
                  <Autocomplete
                    id="select-business-partner"
                    options={partnerList || []}
                    disableClearable
                    getOptionDisabled={(option) =>
                      localStorage.getItem('deepLinkId') &&
                      option.uniqueId !== localStorage.getItem('deepLinkId')
                        ? true
                        : false
                    }
                    getOptionLabel={(option) => option.companyName || ''}
                    PaperComponent={CustomPaper}
                    value={
                      partnerList?.length &&
                      partnerList.find((item) => item?.id === partnerId)
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="filled"
                        id="filled-hidden-label-normal"
                        placeholder="Select..."
                        inputProps={{ 'aria-label': 'Without label' }}
                        hiddenLabel
                        // fullWidth
                        {...params}
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setPartnerId(value.id);
                      }
                    }}
                  />
                  <Box
                    component="div"
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '.75rem',
                      lineHeight: '1.66',
                      textAlign: 'left',
                      margin: '3px 14px 0 14px',
                      color: 'red',
                    }}
                  >
                    {selectBusinessPartnerError && selectBusinessPartnerError}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>

          <ButtonWrapper>
            <Box sx={{ width: 'inherit' }}>
              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{ width: { xs: '-webkit-fill-available', md: '215px' } }}
                  onClick={handleAllow}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loader}
                >
                  Allow
                </LoadingButton>
              </AnimateButton>
            </Box>
          </ButtonWrapper>

          <PolicyAndTermsText />

          <ProductDetails
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            newConsent={newConsent}
            accountDetails={accountDetails}
            onClose={() => setIsOpen(false)} // Correctly defining onClose as a function
          />
        </CommonWrapper>
      </PageWrapper>
    </>
  );
};

export default AccountInformation;
