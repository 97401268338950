import React, { useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ContentStyle,
  FormStyle,
  FormInfoBox,
  StyledTypography,
  FormInputWrapper,
  SignupBg,
} from "../Auth/AuthStyle";

import InputLabel from "../../components/common/formStyle/InputLabel";
import CustomizedCheckbox from "../../components/common/formStyle/CheckBoxStyled";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { useNavigate } from "react-router-dom";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export function EmployeeDetails() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const theme = useTheme();
  const formSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const onSubmit = (data) => {
    // let obj = {
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   email: data.email,
    // };
    // if (!isCheck) {
    //   toast.error("Please select checkbox");
    //   return;
    // }
    // setLoading(true);
    // GetAxiosInstance(`auth/sendOtp`, "post", obj).then((res) => {
    //   if (res?.data?.codes === 200) {
    //     setLoading(false);
    //     navigate('/email-verification', { state: obj });
    //     const postObj = {
    //       journey: 'CUSTOMER_REGISTRATION',
    //       eventName: 'CUSTOMER_REGISTRATION.INITIATED',
    //       status: 'SUCCESS',
    //       uniqueIdentifier: data.email,
    //       subIdentifier: '',
    //       data: {
    //         req: obj,
    //         res: res?.data?.message,
    //       },
    //       source: 'CONNECT',
    //     };
    //     GetAxiosInstance(`log/eventLog`, 'post', postObj);
    //   } else {
    //     setLoading(false);
    //     const postObj = {
    //       journey: 'CUSTOMER_REGISTRATION',
    //       eventName: 'CUSTOMER_REGISTRATION.INITIATED',
    //       status: 'ERROR',
    //       uniqueIdentifier: data.email,
    //       subIdentifier: '',
    //       data: {
    //         req: obj,
    //         res: res?.data?.message,
    //       },
    //       source: 'CONNECT',
    //     };
    //     GetAxiosInstance(`log/eventLog`, 'post', postObj);
    //     toast.error(res?.response?.data?.message || res.message);
    //   }
    // });
  };
  return (
    <>
      <SignupBg>
        <ContentStyle sx={{ minHeight: '100vh' }}>
          <FormStyle
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <StyledTypography variant="Body_semibold_20">
                  Hello
                </StyledTypography>
                <Typography variant="Body_semibold_24">
                Please fill the details of the employee
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid container spacing={4} alignItems="center">
                  <Grid item sm={6} xs={12}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Enter first name"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('firstName')}
                      helperText={errors.firstName && errors.firstName.message}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Enter last name"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('lastName')}
                      helperText={errors.lastName && errors.lastName.message}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} alignItems="center">
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Account Number</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Enter Account Number"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('firstName')}
                      helperText={errors.firstName && errors.firstName.message}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Bank Name</InputLabel>
                    <TextField
                      variant="filled"
                      type="text"
                      placeholder="Bank Name"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('lastName')}
                      helperText={errors.lastName && errors.lastName.message}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter email address"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    {...register('email')}
                    helperText={errors.email && errors.email.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>What professional are you looking to hire?</InputLabel>
                  <TextField
                    variant="filled"
                    type="email"
                    placeholder="Enter your profession"
                    id="filled-hidden-label-normal"
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    {...register('email')}
                    helperText={errors.email && errors.email.message}
                  />
                </Grid>
              </FormInputWrapper>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: '100%' }}
                  // onClick={onSubmit}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Proceed
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </SignupBg>
    </>
  );
}

// Checkbox
